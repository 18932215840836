import { useState, useCallback } from 'react'
import { toast } from 'react-toastify'

import { useTransactionAdder } from 'state/transactions/hooks'

// import { TransactionError } from 'components/UI/TransactionToast'

export default function useCatchTxError() {
  const addTransaction = useTransactionAdder()
  const [loading, setLoading] = useState(false)

  const fetchWithCatchTxError = useCallback(async (callTx, info) => {
    try {
      setLoading(true)

      const tx = await callTx()
      toast.success('Transaction has been sent')
      addTransaction(tx, info)
      const receipt = await tx.wait()
      return receipt
    } catch (error) {
      console.error(error)
      // toast.error(<TransactionError message={info.message} />)
      throw error
    } finally {
      setLoading(false)
    }
  }, [addTransaction])

  return { fetchWithCatchTxError, loading }
}