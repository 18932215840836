import { useEffect, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AuthService from 'API/AuthService'

const ConfirmEmail = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const confirmEmail = useCallback(async () => {
    const code = searchParams.get('code')
    if (!code) {
      navigate('/sign-in', { replace: true, state: { fromConfirmation: true, error: 'Empty code' } })
      return
    }
    try {
      const data = await AuthService.confirmEmail(code)
      if (data.status === 'success') {
        navigate('/sign-in', { replace: true, state: { fromConfirmation: true } })
      } else {
        navigate('/sign-in', { replace: true, state: { fromConfirmation: true, error: data.message } })
      }
    } catch (error) {
      navigate('/sign-in', { replace: true, state: { fromConfirmation: true, error: 'Code does not exist' } })
    }
  }, [navigate, searchParams])

  useEffect(() => {
    confirmEmail()
  }, [confirmEmail])

  return null
}

export default ConfirmEmail
