import UserUpdater from './user/updater'
import TransactionsUpdater from './transactions/updater'

const Updater = () => (
  <>
    <UserUpdater />
    <TransactionsUpdater />
  </>
)

export default Updater
