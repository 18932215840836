import styles from './styles.module.scss'
import { useState } from 'react'

const CustomSelectorUi = ({ tokenSales, onSelectTokenSale }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleItemClick = (tokenSale) => {
    onSelectTokenSale(tokenSale)
    setSelectedItem(tokenSale)
    setIsOpen(false)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} onClick={toggleDropdown}>
        {selectedItem ? (
          <div className={styles.selectedItem}>
            <div className={styles.imgWrapper}>
              <img src={selectedItem.thumbnail?.url} alt={selectedItem.title} />
            </div>
            <div className={styles.infoWrapper}>
              <div className={styles.infoTitle}>
                {`[${selectedItem.token_info.symbol}]`}
                {' '}
                {selectedItem.title}
              </div>
              <div className={styles.infoAddress}>{`${selectedItem.address.address}, ${selectedItem.address.city}, ${selectedItem.address.country}`}</div>
              <div className={styles.infoContract}>{selectedItem.token_info.contract_address}</div>
            </div>
          </div>
        ) : (
          <div className={styles.placeholder}>Choose your property</div>
        )}
      </div>
      {isOpen && (
        <div className={styles.itemsDropDown}>
          {tokenSales.map((tokenSale) => (
            <div key={tokenSale.id} className={styles.dropDownWrapper}>
              <div className={styles.selectedItem} onClick={() => handleItemClick(tokenSale)}>
                <div className={styles.imgWrapper}>
                  <img src={tokenSale.thumbnail?.url} alt={tokenSale.title} />
                </div>
                <div className={styles.infoWrapper}>
                  <div className={styles.infoTitle}>
                    {`[${tokenSale.token_info.symbol}]`}
                    {' '}
                    {tokenSale.title}
                  </div>
                  <div className={styles.infoAddress}>{`${tokenSale.address.address}, ${tokenSale.address.city}, ${tokenSale.address.country}`}</div>
                  <div className={styles.infoContract}>{tokenSale.token_info.contract_address}</div>
                </div>
              </div>
            </div>

          ))}
        </div>
      )}
    </div>
  )
}

export default CustomSelectorUi