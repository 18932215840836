import { NavLink } from 'react-router-dom'

import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'
import { useIsUserAuthorized, useUserDetails } from 'state/user/hooks'

const OrderItem = ({ order, type = null }) => {
  const user = useUserDetails()
  const isUserAuthorized = useIsUserAuthorized()

  if (user?.eth_address === order.seller_address) return

  const sellerName = order.seller_name || 'Unknown'

  return (
    <div  className={styles.wrapper}>
      <div className={styles.leftSection}>
        {/* <OrderStatusUi status={order.status} date={order.created_at} type={order.type} /> */}
        <div className={styles.sellerInfo}>
          <div className={styles.sellerImage}>{sellerName.charAt(0)}</div>
          <div className={styles.sellerName}>
            <p className={styles.fullName}>{sellerName}</p>
            { type === 'investor_ownership' && <p className={styles.orderType}>
              Rights to Musharakah tokens
            </p>}

          </div>
        </div>
        <div className={styles.priceInfo}>
          {formatNative((type === 'investor_ownership') ? order.price : order.token_price)}
          {' '}
          <span>DNR</span>
        </div>
        <div className={styles.limitsInfo}>
          <div className={styles.available}>
            <p className={styles.label}>Available:</p>
            <p className={styles.value}>
              {formatNative(order.available_amount)}
              {' '}
              <span>{order.token_symbol}</span>
            </p>
          </div>
          {/* <div className={styles.limits}>
            <p className={styles.label}>Limits:</p>
            <p className={styles.value}>
              {seller.limitDown}
              {' '}
              ~
              {' '}
              {seller.limitUp}
              {' '}
              <span>DNR</span>
            </p>
          </div> */}
        </div>
      </div>
      { isUserAuthorized &&       <div className={styles.rightSection}>
        {type === 'investor_ownership'
          ?
          (<NavLink to={'/exchange-share'} state={{order: order}} >
            <div className={styles.actionBtn}>
              <div className={styles.btnLink}>buy tokens</div>
            </div>
          </NavLink>)
          // (<NavLink to={{pathname: '/exchange-share', state: { type: type }}}>
          //   <div className={styles.actionBtn}>
          //     <div className={styles.btnLink}>buy tokens</div>
          //   </div>
          // </NavLink>)
          :
          (<NavLink to={`/exchange?id=${order.id}`}>
            <div className={styles.actionBtn}>
              <div className={styles.btnLink}>buy tokens</div>
            </div>
          </NavLink>)}

      </div>}

    </div>
  )
}

export default OrderItem
