export function formatBlockchainAddress(address) {
  if (typeof address !== 'string') {
    throw new Error('Input must be a string')
  }

  // Проверяем, что строка соответствует формату блокчейн адреса
  const addressRegex = /^0x[0-9a-fA-F]{40}$/
  if (!addressRegex.test(address)) {
    throw new Error('Invalid blockchain address format')
  }

  // Извлекаем первые 12 символов и последние 4 символа
  const prefix = address.substring(0, 12)
  const suffix = address.substring(address.length - 4)

  // Возвращаем отформатированную строку
  return `${prefix}...${suffix}`
}

export function truncateTxHash(hash) {
  return `${hash.slice(0,8)}...${hash.slice(-8)}`
}
