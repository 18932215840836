import styles from './styles.module.scss'

const BuyTokenInput = ({
  label,
  name,
  value,
  onChange,
  type = 'text',
  disabled = false,
  autoComplete = 'off',
  // error = null,
  placeholder = '',
  staticLabelText = '',
  actionLabelText = '',
  actionLabelFunc = null,
  error = null,
}) => {
  return (
    <div className={styles.customInputWrapper}>
      <p className={styles.label}>{label}</p>
      <p className={styles.actionLabel}>
        {staticLabelText}
        :
        {' '}
        <span onClick={actionLabelFunc} className={styles.maxBalance}>
          {actionLabelText}
        </span>
      </p>
      <input
        type={type}
        name={name}
        className={styles.customInput}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        placeholder={placeholder}
        // pattern="^([0-9]*\.?[0-9]*)$"
        // pattern="^([0-9]+(?:[.,][0-9]*)?)$"
        // pattern="^([0-9]+(?:[.,][0-9]{0,2})?)$"
        inputMode="decimal"
      />
      <p className={styles.errorMessage}>{error}</p>
    </div>
  )
}

export default BuyTokenInput
