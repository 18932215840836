import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  gnosisSafeConnection,
  networkConnection,
  useGetConnection,
} from 'web3/connection'

import { updateSelectedWallet } from 'state/wallet/reducer'
import { useSelectedWallet } from 'state/wallet/hooks'
import { useSiteNetworkId } from 'state/network/hooks'

async function connect(connector, chainId) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly()
    } else {
      await connector.activate(chainId)
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`)
  }
}

export default function useEagerlyConnect() {
  const dispatch = useDispatch()

  const getConnection = useGetConnection()
  const selectedWallet = useSelectedWallet()
  const siteNetworkId = useSiteNetworkId()

  let selectedConnection
  if (selectedWallet) {
    try {
      selectedConnection = getConnection(selectedWallet)
    } catch {
      dispatch(updateSelectedWallet(undefined))
    }
  }

  useEffect(() => {
    connect(gnosisSafeConnection.connector)
    connect(networkConnection.connector, siteNetworkId)

    if (selectedConnection) {
      connect(selectedConnection.connector)
    }
  }, [])
}
