import styles from './styles.module.scss'

export const TransactionSuccessed = ({ message }) => (
  <div>
    <h2 className={styles.success}>
      Transaction Succeed
    </h2>
    <div>
      {message}
    </div>
  </div>
)

export const TransactionError = ({ message }) => (
  <div>
    <h2 className={styles.error}>
      Transaction Error
    </h2>
    <div>
      {message}
    </div>
  </div>
)
