import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import styles from './styles.module.scss'
import closeIcon from '../../../assets/images/icons/close.svg'

const ModalUi = ({ isOpen, close, header, children, width = 780 }) => {
  const [shouldRender, setShouldRender] = useState(isOpen)

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true)
    }
  }, [isOpen])

  const onAnimationEnd = () => {
    if (!isOpen) {
      setShouldRender(false)
    }
  }

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      close()
    }
  }

  const modalStyle = {
    width: `${width}px`,
  }

  return shouldRender ? createPortal(
    <div
      className={`${styles.modalOverlay} ${
        isOpen ? styles.active : styles.inactive
      }`}
      onAnimationEnd={onAnimationEnd}
      onClick={handleOverlayClick}
    >
      <div className={styles.modal} style={modalStyle}>
        <div className={styles.header}>
          <div className={styles.closeButton} onClick={close}>
            <img src={closeIcon} alt="" />
          </div>
          <p className={styles.headerTitle}>{header}</p>
        </div>
        {children}
      </div>
    </div>,
    document.body,
  ) : null
}

export default ModalUi
