import { Navigate } from 'react-router-dom'

import { useIsUserAuthorized } from 'state/user/hooks'

const ProtectedRoute = ({ children }) => {
  const isUserAuthorized = useIsUserAuthorized()

  if (!isUserAuthorized) {
    return (
      <Navigate
        to="/sign-in"
        state={{ error: 'Please log in to continue' }}
        replace
      />
    )
  }
  return children
}

export default ProtectedRoute
