import moment from 'moment'

import Transaction from 'components/UI/Transaction'

import styles from './styles.module.scss'

const TransactionsSection = ({ grouppedTransactions }) => (
  <div className={styles.transactionsSection}>
    {Object.keys(grouppedTransactions).map(date => (
      <div key={date} className={styles.transactionSection}>
        <div className={styles.date}>
          {moment(date).format('DD MMM, YYYY')}
        </div>
        {grouppedTransactions[date].filter(transaction => transaction.type !== 'cancel_sell_shares_order').map((item) => {
          return <Transaction key={item.id} transaction={item} tokenSymbol={item.tokenSymbol} tokenPrice={item.tokenPrice} />
        })}
      </div>
    ))}
  </div>
)

export default TransactionsSection


{/* <div className={styles.transactions}>
  {asset.transactions
    .filter(transaction => transaction.type !== "sell_share_order")
    .map((transaction) => (
      <Transaction
        key={transaction.id}
        transaction={transaction}
        tokenSymbol={asset.symbol}
        tokenPrice={asset.price}
      />
    ))}
</div> */}