import { useState, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AuthService from 'API/AuthService'

import useFormValidator from 'hooks/useFormValidator'

import CustomInputUi from 'components/UI/CustomInputUi'

import resetPasswordValidator from 'validators/resetPasswordValidator'

import styles from './styles.module.scss'

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { inputErrors, setInputErrors, isFormValid } =
    useFormValidator(resetPasswordValidator)

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirmation: '',
  })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleInputChange = ({ target }) => {
    const { name, value } = target
    setFormData((prevData) => ({ ...prevData, [name]: value?.trim() }))
    setInputErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
  }

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    setError('')

    const code = searchParams.get('code')
    if (!code) {
      setError('Empty reset code')
      return
    }

    if (!isFormValid(formData)) {
      return
    }
    setIsLoading(true)

    try {
      await AuthService.resetPassword(code, formData.password)
      navigate('/sign-in', { replace: true, state: { fromReset: true } })
    } catch (error) {
      console.log(error)
      setError(error?.response?.data?.message || 'Failed')
    } finally {
      setIsLoading(false)
    }
  }, [formData, searchParams, navigate])

  return (
    <div className={styles.wrapper}>
      <h4>Reset password</h4>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className={styles.formWrapper}
      >
        <CustomInputUi
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleInputChange}
          disabled={isLoading}
          error={inputErrors.password}
          autoComplete="off"
        />
        <CustomInputUi
          label="Confirm Password"
          name="passwordConfirmation"
          type="password"
          value={formData.passwordConfirmation}
          onChange={handleInputChange}
          disabled={isLoading}
          error={inputErrors.passwordConfirmation}
          autoComplete="off"
        />
        {error && <div className={styles.errorMessage}>{error}</div>}
        <button
          className={styles.buttonForm}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Reset'}
        </button>
      </form>
    </div>
  )
}

export default ResetPassword
