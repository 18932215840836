import styles from './styles.module.scss'
import SouhaylPhotoImg from 'assets/images/photos/Souhayl.png'
import AlqudahPhotoImg from 'assets/images/photos/Drmain.jpeg'
import MaroufPhotoImg from 'assets/images/photos/Marouf.jpg'

const About = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.maintTitle}>EdificeX: Where Ethics & Profit Meet</div>
      <div className={styles.section}>
        <div className={styles.text}>We are EdificeX and we are here to democratize ethical property purchase and investment. The key factors enabling our success are: </div>
        <div className={styles.textStrong}>
          <div className={styles.text}>
            <br />
            1. The technology driving our solution
            <br />
            2. Our commitment to ethical finance
            <br />
            3. The value we offer global home buyers and investors
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Let’s begin with the technology</div>
        <div className={styles.text}>

        </div>
      </div>
      <div className={styles.section}>
        {/* <div className={styles.title}>Our Story</div> */}
        <div className={styles.text}>
          Our product implements the declining partnership model using a novel technology called real estate tokenization. This is how it works:
        </div>
        <div className={styles.text}>
          <br />
          1. Sellers divide their property into fractions and convert them into digital tokens on our platform.
          <br />
          2. Investors become fractional owners by purchasing a subset of these property tokens.
          <br />
          3. The first investor to choose the home buyer option can gradually redeem all tokens, becoming the sole owner of the property.
          <br />
          4. The home buyer rents the property and rent income is distributed among investors.
        </div>

        <div className={styles.text}>
          <br />
          All of that happens on our customized blockchain, whose native currency is uniquely backed by gold, making it the only non-volatile cryptocurrency in the space. Our blockchain offers the following benefits:
        </div>
        <div className={styles.text}>
          <br />
          &#x2022; Global accessibility
          <br />
          &#x2022; Immutability
          <br />
          &#x2022; Transparency
          <br />
          &#x2022; Stability
        </div>
        <br />
        <br />
        <br />
        <div className={styles.title}>However, our solution is not only about Crypto stability, it is also about ethical stability.</div>
        <div className={styles.text}>
          <br />
          From the novel technology we use to the way we develop our products, we always prioritize ethical profitability. This commitment means that all contracts involved in our solution are ethically structured. Therefore, no interest, direct or indirect, is accrued or paid.
        </div>
        <br />
        <br />
        <br />
        <div className={styles.title}>This takes us to the value we offer home buyers and investors</div>
        <div className={styles.text}>
          <br />
          Our novel solution allows buyers to purchase a portion of a property and buy the rest when they can. No banks, no loans, and no interest.
        </div>
        <div className={styles.text}>
          <br />
          Furthermore, we make real estate investment accessible to middle and low-income families and investors worldwide, allowing them to profit from rental income and property appreciation.
        </div>
        <div className={styles.text}>
          <br />
          This is our solution, where ethics & profit meet.
        </div>

      </div>
      {/* <div className={styles.section}>
        <div className={styles.title}>Our Product</div>
        <div className={styles.text}>
          <ul>
            <li>Our real estate investment and home ownership solutions have been designed to remove barriers to entry for the Muslim Community.</li>
            <li>We are the first in the market to offer an uncompromisingly Shari’ah-compliant product that makes halal investment and home ownership accessible to Muslims.</li>
            <li>Our solution uses the novel real estate tokenization technology.</li>
            <li>The native currency of the blockchain that powers our tokenization solution is uniquely backed by fiat and is, therefore, the only fully stable blockchain in the space.</li>
            <li>Our use of blockchain provides greater transparency, increased liquidity, democratization through fractional ownership, ease of access, transferability of assets, and efficient transactions.</li>
            <li>Our platform design is simple and gives customers the targeted information they need to manage their investment portfolios.</li>
            <li>Our product is designed with the help and input of experts in the fields of Islamic finance and blockchain.</li>
          </ul>
        </div>
      </div> */}
      {/* <div className={styles.section}>
        <div className={styles.title}>Why Real Estate Tokenization</div>
        <div className={styles.text}>
          <ul>
            <li>The traditional real estate market is shifting, as tokenization emerges as a transformative process.</li>
            <li>Tokenization is reinventing what ownership in real estate means and making investment more affordable to more people.</li>
            <li>Real estate tokens now represent approximately 40% of the digital securities market and the real estate tokenization market is surpassing $20 billion worldwide.</li>
            <li>Tokenization of global assets is expected to become a $16 trillion industry by 2030, with $4 trillion contributed by real estate asset tokenization.</li>
            <li>Tokenization of real estate provides greater transparency, security, increased liquidity in a traditionally illiquid market, and democratization of investment through fractional ownership. </li>
          </ul>
        </div>
      </div> */}
      <div className={styles.additionalTitle}>Our Team</div>
      <div className={styles.subSection}>
        <div className={`${styles.section} ${styles.width50}`}>
          <div className={styles.teamTitle}>
            <div className={styles.photoWrapper}>
              <img src={SouhaylPhotoImg} alt="" />
            </div>
            Souhayl Maronesy
            <p className={styles.jobTitle}>Founder, Chief Executive and Technology Officer</p>
            {' '}
          </div>
          <div className={styles.text}>Souhayl Maronesy holds a bachelor&apos;s degree in Electrical Engineering from SDSU and a master&apos;s degree in Computer Science from UIUC. He has worked for renowned tech companies such as Ericsson and Plaid. He recently departed his Software Engineering position at ResMed to focus entirely on EdificeX. Souhayl is a public speaker who teaches Arabic, poetry, theology, and legal theory in his free time. He enjoys working out and is often found outdoors.</div>
        </div>
        <div className={`${styles.section} ${styles.width50}`}>
          <div className={styles.teamTitle}>
            <div className={styles.photoWrapper}>
              <img src={AlqudahPhotoImg} alt="" />
            </div>
            {' '}
            Dr. Main Al-Qudah
            <p className={styles.jobTitle}>Co-Founder and Ethical Finance Officer</p>
            {' '}
          </div>
          <div className={`${styles.text} ${styles.textRight}`}>Dr. Main Alqudah is the President and Co-founder of Guidance College in Houston. He is an Associate Professor of Finance with more than 23 years of experience. Dr. Alqudah holds PhDs from the American Open University and Al-Azhar University, an MA in Economics from Al Yarmook University in Jordan, and a Bachelor of Arts in Economics from Al-Azhar University. Dr. Alqudah&apos;s extensive knowledge of finance and its practical applications have earned him opportunities to benefit a variety of audiences. He also serves as a consultant for many finance institutions in the US and Europe.</div>
        </div>
      </div>
      <div className={styles.subSection}>
        <div className={`${styles.section} ${styles.width50}`}>
          <div className={styles.teamTitle}>
            <div className={styles.photoWrapper}>
              <img src={MaroufPhotoImg} alt="" />
            </div>
            {' '}
            Marouf Ahmed
            <p className={styles.jobTitle}>Co-Founder and Chief Operations Officer</p>
            {' '}
          </div>
          <div className={styles.text}>Marouf is a seasoned executive & strategist with more than two decades of experience in the finance and insurance industry working for a Fortune 500 company. Developing diverse, high-performing teams and driving comprehensive organization-wide strategy and execution, Marouf has a demonstrated track record of initiating and implementing transformational process and policy changes enterprise-wide, balancing strategy and execution discipline across all facets of an organization to help drive business results.  Marouf is also the co-founder of Fabled, a DTC boutique online jewelry startup. He obtained his BBA with a major in Business Computer Information Systems from the Frank G. Zarb School of Business at Hofstra University in NY and his MBA from the University at Buffalo School of Management in NY. </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Contact Information</div>
        <div className={styles.text}>EdificeX</div>
        <div className={styles.text}>
          Email: info@bunyan.io
        </div>
        <div className={styles.text}>Phone: 6194169878</div>
      </div>
    </div>
  )
}

export default About