// useFormValidator.js
import { useState } from 'react'

const useFormValidator = (validator) => {
  const initialErrors = Object.keys(validator).reduce(
    (acc, fieldName) => ({ ...acc, [fieldName]: '' }),
    {},
  )

  const [inputErrors, setInputErrors] = useState(initialErrors)

  const isFormValid = (formData) => {
    let isValid = true
    const errors = {}

    for (const fieldName in validator) {
      const rules = validator[fieldName]
      const value = formData[fieldName].replaceAll(' ', '')

      if (rules.minDate && new Date(value) < new Date(rules.minDate)) {
        errors[fieldName] = rules.errorMessage
        isValid = false
      }

      if (rules.required && !!value && rules.pattern && !rules.pattern.test(value)) {
        errors[fieldName] = rules.errorMessage
        isValid = false
      }

      if (rules.minLength && value.length < rules.minLength) {
        errors[fieldName] = rules.errorMessage
        isValid = false
      }

      if (rules.required && !value) {
        errors[fieldName] = `${
          fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
        } is required`
        isValid = false
      }
    }

    setInputErrors(errors)
    return isValid
  }

  return { inputErrors, setInputErrors, isFormValid }
}

export default useFormValidator
