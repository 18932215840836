import styles from './styles.module.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import JustLogoIcon from 'assets/images/just_logo.svg'

const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <footer className={styles.content}>
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-md-2">
              <div className={styles.iconWrapper}>
                <img src={JustLogoIcon} alt="" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <p className="text-justify">
                EdificeX democratizes real estate ownership by implementing the
                declining partnership mode of finance through an innovative
                technology called real estate tokenization. Our solution allows
                buyers to acquire portions of a property and gradually purchase
                the rest as their finances permit. We make the home-buying
                process flexible, accessible, interest-free, and seamless.
                Edifice Inc. operates this site without being a registered
                broker-dealer or investment advisor, does not provide investment
                advice or recommendations, and emphasizes that all investment
                decisions are the responsibility of the investor, who should
                consult with legal and financial professionals.
              </p>
            </div>
            <div className="col-sm-4 col-md-2">
              <h6 className={styles.h6}>Quick Links</h6>
              <ul className={styles.footerLinks}>
                <li className={styles.li}>
                  <a className={styles.a} href="https://edificex.ai/about">
                    About
                  </a>
                </li>
                <li className={styles.li}>
                  <a
                    className={styles.a}
                    href="https://edificex.ai/marketplace"
                  >
                    Marketplace
                  </a>
                </li>
                <li className={styles.li}>
                  <a className={styles.a} href="https://edificex.ai/wallet">
                    Wallet
                  </a>
                </li>
                <li className={styles.li}>
                  <a
                    className={styles.a}
                    href="https://edificex.ai/p2p-exchange"
                  >
                    P2P Exchange
                  </a>
                </li>
                <li className={styles.li}>
                  <a
                    className={styles.a}
                    href="https://edificex.ai/request-tokensale"
                  >
                    Request Tokensale
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-4 col-md-2">
              <h6 className={styles.h6}>Contact</h6>
              <ul className={styles.footerLinks}>
                <li className={styles.li}>info@edificex.ai</li>
                <li className={styles.li}>+16194169878</li>
              </ul>
            </div>
          </div>
          <hr className={styles.hr} />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-6 col-xs-12">
              <p className={styles.copyrightText}>
                Copyright &copy; 2024 All Rights Reserved by&nbsp;
                <a href="https://edificex.ai/">EdificeX</a>
              </p>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <ul className={styles.socialIcons}>
                <li>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/people/EdificeX/61550900795047/"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="youtube"
                    href="https://www.youtube.com/@edificex"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/company/edificex?trk=similar-pages"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
