import { useState, useMemo } from 'react'

import Transaction from 'components/UI/Transaction'

import tokenLogoImg from 'assets/images/icons/edifice-logo.svg'
import arrowDonwImg from 'assets/images/icons/arrow-down.svg'

import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'

const PortfolioAssetUi = ({ asset }) => {
  const [isOpen, setIsOpen] = useState(false)

  const totalAmount = useMemo(() => {
    return asset.transactions.reduce((total, tx) => {
      const bigAmount = (tx?.data?.amount) ? BigInt(tx.data.amount) : 0
      if (tx.type === 'buy_tokens' || tx.type === 'transfer_in') {
        return total + bigAmount
      } else if (tx.type === 'refund_tokens' || tx.type === 'transfer_out') {
        return total - bigAmount
      } else if (tx.type === 'claim_musharakah') {
        return total - BigInt(tx.data.token_amount || '0')
      }
      return total
    }, BigInt(0))
  }, [JSON.stringify(asset.transactions)])

  //cancel_sell_shares_order

  const toggleCollapse = () => setIsOpen(!isOpen)

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} onClick={toggleCollapse}>
        <div className={styles.assetInfo}>
          <div className={styles.imgWrapper}>
            {asset.iconUrl ? (
              <img src={asset.iconUrl} alt="" />
            ) : (
              <img src={tokenLogoImg} alt="" />
            )}
          </div>
          <div className={styles.txtInfo}>
            <p className={styles.label}>{asset.name}</p>
            <p className={styles.symbol}>{asset.symbol}</p>
          </div>
        </div>
        <div className={styles.amount}>
          <div className={styles.value}>
            {formatNative(totalAmount, 2)}
          </div>
          <img
            className={isOpen ? styles.rotate : ''}
            src={arrowDonwImg}
            alt=""
          />
        </div>
      </div>
      {isOpen && (
        <div className={styles.content}>
          <div className={styles.transactions}>
            {asset.transactions
              .filter(transaction => transaction.type !== 'cancel_sell_shares_order')
              .map((transaction) => (
                <Transaction
                  key={transaction.id}
                  transaction={transaction}
                  tokenSymbol={asset.symbol}
                  tokenPrice={asset.price}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default PortfolioAssetUi
