import { useState, useEffect } from 'react'
import TokenSaleService from '../API/TokenSaleService'
import { useUserDetails } from 'state/user/hooks'

export function useTokenSales() {
  const [tokenSales, setTokenSales] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchTokenSales = async () => {
      try {
        const data = await TokenSaleService.getTokenSales()
        setTokenSales(data)
      } catch (error) {
        setError('Failed to fetch tokensales')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchTokenSales()
  }, [])

  return { tokenSales, isLoading, error }
}

// fetchSellingMusharakah: async () => {
//   const { data } = await api.get('/token-sales/selling-musharakah')
//   return data.data
// },

export function useSellingMusharakah() {
  const [sellingMusharakah, setSellingMusharakah] = useState([])
  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState(null)

  useEffect(() => {
    const fetchSellingMusharakah = async () => {
      try {
        const data = await TokenSaleService.fetchSellingMusharakah()
        setSellingMusharakah(data)
      } catch (error) {
        setIsError('Failed to fetch selling Musharakah items')
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchSellingMusharakah()
  }, [])

  return { sellingMusharakah, loading, isError }
}

export function useTokenSale(id) {
  const [tokenSale, setTokenSale] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchTokenSale = async () => {
      try {
        const data = await TokenSaleService.getTokenSale(id)
        setTokenSale(data)
      } catch (error) {
        setError('Failed to fetch tokensales')
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchTokenSale()
  }, [])

  return { tokenSale, isLoading, error }
}

export function useFilteredTokenSales() {
  const { tokenSales, isLoading: isLoadingTokenSales, error: tokenSalesError } = useTokenSales()
  const userDetails = useUserDetails()
  const [filteredTokenSales, setFilteredTokenSales] = useState([])

  useEffect(() => {
    if (!isLoadingTokenSales && userDetails.tokens) {
      const userTokenAddresses = Object.keys(userDetails.tokens)
      const filtered = tokenSales.items.filter(tokenSale =>
        userTokenAddresses.includes(tokenSale.token_info.contract_address),
      )
      setFilteredTokenSales(filtered)
    }
  }, [tokenSales, isLoadingTokenSales, userDetails])

  return { filteredTokenSales, isLoading: isLoadingTokenSales, error: tokenSalesError }
}