import styles from './styles.module.scss'

const CustomInputUi = ({
  label,
  name,
  value,
  onChange,
  type = 'text',
  disabled,
  autoComplete = 'off',
  error,
  hint = false,
  placeholder = '',
}) => {
  return (
    <>
      <div className={styles.inputWrapper}>
        <label>
          {label}
          :
        </label>
        <input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          autoComplete={autoComplete}
          className={`${styles.formInput} ${error ? styles.inputError : ''}`}
        />
        {error && <div className={styles.error}>{error}</div>}
        {hint && <p className={styles.hintExample}>{hint}</p>}
      </div>
    </>
  )
}

export default CustomInputUi
