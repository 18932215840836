import { useState, useMemo, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { NavLink } from 'react-router-dom'
import { formatEther, parseEther } from 'ethers'
import cx from 'classnames'

import {
  useTokenBalance,
  useTokenSaleSuccessed,
  useTokenExchangeAllowance,
  useTokenExchangeApprove,
} from 'hooks/useRealEstateToken'
import { useCreateOrder } from 'hooks/useExchange'
import { useIsCorrectAddress, useIsWalletConnected } from 'state/user/hooks'
import { useActiveNetwork } from 'state/network/hooks'

import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'
import ErrorBuyBanner from 'components/UI/ErrorBuyBanner'
import SuccessBuyBanner from 'components/UI/SuccessBuyBanner'
import GlobalPreloader from 'components/UI/GlobalPreloader'
import { useFilteredTokenSales } from 'hooks/useTokenSales'
import CustomSelectorUi from 'components/UI/CustomSelectorUi'

const CreateOrder = () => {
  const { account } = useWeb3React()
  const [selectedToken, setSelectedToken] = useState('')
  const tokenBalance = useTokenBalance(selectedToken)
  const saleSuccessed = useTokenSaleSuccessed(selectedToken)
  const allowance = useTokenExchangeAllowance(selectedToken)
  const isWalletConnected = useIsWalletConnected()

  const { filteredTokenSales, isLoading, error } = useFilteredTokenSales()

  const activeNetwork = useActiveNetwork()
  const isCorrectAddress = useIsCorrectAddress()

  const { loading, onApprove } = useTokenExchangeApprove(selectedToken, '')
  const { loading: processingCreateOrder, onCreateOrder } = useCreateOrder(selectedToken)

  const [tokenAmount, setTokenAmount] = useState('')
  const [tokenPrice, setTokenPrice] = useState('')

  const [formError, setFormError] = useState('')

  const [successPurchase, setSuccessPurchase] = useState(false)

  const hasEnoughAllowance = useMemo(() => {
    if (!tokenAmount) {
      return false
    }
    return BigInt(allowance) >= parseEther(tokenAmount)
  }, [allowance, tokenAmount])

  const shouldApprove = tokenAmount && !hasEnoughAllowance


  const canCreate = useMemo(() => {
    if (!tokenAmount || !tokenPrice || !saleSuccessed) {
      return false
    }
    const amt = parseEther(tokenAmount)
    const price = parseEther(tokenPrice)
    if (amt === 0n || amt > parseEther(tokenBalance) || price === 0n) {
      return false
    }

    return true
  }, [tokenAmount, tokenPrice, tokenBalance])

  const getMaxAmount = useCallback(() => {
    setTokenAmount(formatEther(tokenBalance))
  }, [tokenBalance])

  const handleApprove = useCallback(async () => {
    await onApprove()
  }, [onApprove])

  const handleCreateOrder = useCallback(async () => {
    setFormError('')
    if (!canCreate) {
      return
    }

    const amount = parseEther(tokenAmount)
    const price = parseEther(tokenPrice)

    if (amount > BigInt(tokenBalance)) {
      setFormError('You have indicated an amount that exceeds your balance')
      return
    }

    try {
      const reciept = await onCreateOrder(amount, price)
      if (reciept.status === 1) {
        // success transaction
        setSuccessPurchase(true)
      }
      setTokenAmount('')
      setTokenPrice('')
    } catch (error) {
      console.log(error)
    }

  }, [tokenAmount, tokenPrice, canCreate, onCreateOrder])

  const onSelectTokenSale = (tokeSale) => {
    setSelectedToken(tokeSale.token_info.contract_address)
  }

  const handleTokenAmountChange = event => {
    let { value } = event.target

    if (value === '.') {
      value = '0.'
    } else if (!/^\d*\.?\d*$/.test(value)) {
      return
    }
    setTokenAmount(value)
  }

  const handleTokenPriceChange = event => {
    let { value } = event.target
    if (value === '.') {
      value = '0.'
    } else if (!/^\d*\.?\d*$/.test(value)) {
      return
    }
    setTokenPrice(value)
  }

  const getNonMusharakahTokenSales = (tokenSales) => {
    const filteredTokenSales = tokenSales.filter(tokenSale =>
      (tokenSale.token_info.is_musharakah === false) || (tokenSale.token_info.is_musharakah === true && tokenSale.token_info.is_musharakah_paid === true),
    )

    return filteredTokenSales
  }

  if (isLoading) return <GlobalPreloader />
  if (error) return <div>
    Error:
    {error}
  </div>

  const renderFirstError = () => {
    if (!isWalletConnected) {
      return <ErrorBuyBanner label="Wallet not connected" desc="" />
    }
    if (!isCorrectAddress) {
      return (
        <ErrorBuyBanner
          label="Your wallet address does not match your account"
          desc="When registering, each user adds his wallet address and only with the help of it can he perform all financial transactions. Please switch to your wallet address that you specified when registering in your Metamask."
        />
      )
    }
    if (!activeNetwork) {
      return (
        <ErrorBuyBanner
          label="You are using the wrong network"
          desc="Our application uses Edifice's own blockchain. Please switch to the Edifice network in your Metamask settings."
        />
      )
    }
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.backTolink}>
        <NavLink to="/wallet" className={styles.backTolink}>
          Back to Wallet page
        </NavLink>
      </div>
      <div className={styles.buyForm}>
        {processingCreateOrder && <GlobalPreloader />}
        {renderFirstError()}
        { successPurchase && <SuccessBuyBanner text="The order creation was successful. You can see a list of all your orders on the wallet page in the Orders section" /> }
        <h4>Create order</h4>
        <p className={styles.description}>
          The purchase of your tokens occurs automatically and does not require
          additional actions from you. Funds after your token sale are
          automatically credited to your wallet address, which is in your
          profile
        </p>
        {formError && <ErrorBuyBanner label="Error" desc={formError} />}
        {selectedToken && !saleSuccessed ? (
          <ErrorBuyBanner label="Token sale is not over yet" desc="You cannot create orders using tokens of real estate objects whose token sales have not ended. Wait until the end of the token sale to create an order to sell tokens." />
        ) : null}
        <div className={styles.addressBanner}>
          <p className={styles.address}>
            {account}
          </p>
          <p className={styles.description}>
            Wallet address where funds will be sent after someone buys your
            tokens
          </p>
        </div>
        <div className={styles.formWrapper}>
          <div className={styles.customInputWrapper}>
            <CustomSelectorUi onSelectTokenSale={onSelectTokenSale} tokenSales={getNonMusharakahTokenSales(filteredTokenSales)} />
          </div>
        </div>
        {selectedToken && saleSuccessed ? (
          <div className={styles.formWrapper}>
            <div className={styles.customInputWrapper}>
              <p className={styles.label}>How many tokens do you want to sell?</p>
              <p className={styles.actionLabel}>
                Your balance:
                {' '}
                <span onClick={getMaxAmount} className={styles.maxBalance}>
                  {`${formatNative(tokenBalance)} RET-RFDGT2`}
                </span>
              </p>
              <input
                type="text"
                className={styles.customInput}
                value={tokenAmount}
                // pattern="^([0-9]+(?:\.[0-9]*)?)$"
                inputMode="decimal"
                onChange={handleTokenAmountChange}
              />
            </div>
            <div className={styles.customInputWrapper}>
              <p className={styles.label}>At what price do you want to sell?</p>
              <p className={styles.actionLabel}>
                Specify the desired price for 1 token
              </p>
              <input
                type="text"
                className={styles.customInput}
                value={tokenPrice}
                // pattern="^([0-9]+(?:\.[0-9]*)?)$"
                inputMode="decimal"
                onChange={handleTokenPriceChange}
              />
            </div>
            {/* <div className={styles.customInputWrapper}>
              <p className={styles.label}>Minimum limit</p>
              <p className={styles.actionLabel}>
                Specify the minimum number of tokens to purchase per order
              </p>
              <input type="text" className={styles.customInput} />
            </div>
            <div className={styles.customInputWrapper}>
              <p className={styles.label}>Maximum limit</p>
              <p className={styles.actionLabel}>
                Specify the maximum number of tokens to purchase per order
              </p>
              <input type="text" className={styles.customInput} />
            </div> */}
            <div className={styles.actionBtnWrapper}>
              {shouldApprove ? (
                <div className={cx(styles.btn, {[styles.disabled]: loading})} onClick={handleApprove}>Approve tokens</div>
              ) : (
                <div
                  className={cx(styles.btn, {[styles.disabled]: !canCreate || processingCreateOrder})}
                  onClick={handleCreateOrder}
                >
                  Create order
                </div>
              )}
              {/* {hasEnoughAllowance ? (
                <p className={cx(styles.btn, {[styles.disabled]: !tokenAmount})}>Create order</p>
              ) : (
                <p className={cx(styles.btn, {[styles.disabled]: loading})} onClick={handleApprove}>Approve tokens</p>
              )} */}
            </div>
            {
              !canCreate && <p className={styles.feeInfo}>
                Fill in all fields to create an order
              </p>
            }

            {
              shouldApprove && <p className={styles.feeInfo}>
                In order to create an order, you need to allow the exchange smart contract to interact with your tokens.
              </p>
            }

          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CreateOrder
