import styles from './styles.module.scss'

const StatsCard = ({title, amount, link=null}) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{title}</p>
      {
        (link) ? <a href={link} target='_blance'>{amount}</a> : <p className={styles.amount}>{amount}</p>
      }
    </div>
  )
}

export default StatsCard