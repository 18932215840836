import React from 'react'
import ReactDOM from 'react-dom/client'
import BigNumber from 'bignumber.js'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// import { ToastContainer, Zoom } from 'react-toastify'

import { BlockNumberProvider } from 'lib/hooks/useBlockNumber'
import Web3Provider from 'components/Web3Provider'

import App from './App'

import store, { persistor } from './state'
import Updater from './state/updater'

import 'react-toastify/dist/ReactToastify.css'
import '../src/assets/styles/index.scss'

BigNumber.config({ DECIMAL_PLACES: 18 })

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Web3Provider>
          <BlockNumberProvider>
            <Updater />
            <App />
            {/* <ToastContainer
              theme="light"
              autoClose={5000}
              transition={Zoom}
              position="bottom-left"
            /> */}
          </BlockNumberProvider>
        </Web3Provider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)