import { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import ImgCoverUi from '../../components/UI/ImgCoverUi'
import styles from './styles.module.scss'
import ArrowRightIcon from '../../assets/images/icons/arrow-right.svg'
import LocationIcon from '../../assets/images/icons/location.svg'
import SqftIcon from '../../assets/images/icons/sqft.svg'
import BedroomIcon from '../../assets/images/icons/bedroom.svg'
import HouseIcon from '../../assets/images/icons/house.svg'
import BathroomIcon from '../../assets/images/icons/bathroom.svg'
import FloorIcon from '../../assets/images/icons/floor.svg'
import DetailsTabsUi from '../../components/UI/DetailsTabsUi'
import DataCardUi from '../../components/UI/DataCardUi'
import DocumentIcon from '../../assets/images/icons/document.svg'
import DownloadIcon from '../../assets/images/icons/download.svg'
import ScIcon from 'assets/images/icons/sc-icon.svg'
import { useTokenSale } from '../../hooks/useTokenSales'
import ModalUi from '../../components/UI/ModalUi'
import BuyTokenForm from '../../components/BuyTokenForm'

import { formatNumber, formatNative } from 'utils/formatNumber'
import { convertTokensToDNR } from 'utils/calculate'

import GlobalPreloader from 'components/UI/GlobalPreloader'
import BuyShareMusharakahForm from 'components/BuyShareMusharakahForm'
import { useIsUserAuthorized, useUserDetails } from 'state/user/hooks'

const TokenSale = () => {
  const { id } = useParams()
  const { tokenSale, isLoading, error } = useTokenSale(id)

  const user = useUserDetails()
  const isUserAuthorized = useIsUserAuthorized()

  const isCurrentUserBorrower = user?.eth_address === tokenSale?.token_info?.musharakah_borrower

  const [isModalOpen, setIsModalOpen] = useState({ isOpen: false, isRequestOwnerPurchase: false })
  const toggleModal = (isRequestOwner = false) => {
    setIsModalOpen({
      isOpen: !isModalOpen.isOpen,
      isRequestOwnerPurchase: isRequestOwner,
    })
  }

  const [isBuyShareModalOpen, setIsBuyShareModalOpen] = useState(false)
  const toggleModalBuyShare = () => setIsBuyShareModalOpen(!isBuyShareModalOpen)

  if (isLoading) return <GlobalPreloader />
  if (error) {
    return (
      <div>
        Error:
        {' '}
        {error}
      </div>
    )
  }

  const hardcap = BigInt(tokenSale.token_info.hardcap)
  const totalSupply = BigInt(tokenSale.token_info.total_supply)
  const tokensLeft = BigInt(tokenSale.token_info.musharakah_tokens_left)
  const price = BigInt(tokenSale.token_info.price)
  const availableAmount = hardcap - totalSupply
  const borrowerTokensLeft = totalSupply - tokensLeft

  const OverviewSection = () => {
    return (
      <div className={styles.overviewSection}>
        <div className={styles.infoIcons}>
          <div className={styles.infoIcon}>
            <img src={SqftIcon} alt="sqft" />
            <div className={styles.desc}>452 SQFT</div>
          </div>
          <div className={styles.infoIcon}>
            <img src={BedroomIcon} alt="bedroom" />
            <div className={styles.desc}>2 Bedrooms</div>
          </div>
          <div className={styles.infoIcon}>
            <img src={HouseIcon} alt="house" />
            <div className={styles.desc}>House</div>
          </div>
          <div className={styles.infoIcon}>
            <img src={BathroomIcon} alt="bathroom" />
            <div className={styles.desc}>3 Bathrooms</div>
          </div>
          <div className={styles.infoIcon}>
            <img src={FloorIcon} alt="floor" />
            <div className={styles.desc}>2 Floors</div>
          </div>
        </div>

        <div className={styles.aboutInfo}>
          <div className={styles.title}>About this space</div>
          <div className={styles.text}>{tokenSale.description}</div>
        </div>

        <div className={styles.aboutInfo}>
          <div className={styles.title}>Project development</div>
          <div className={styles.text}>
            Project is a development property in 1220 Vienna, with a total area
            of 2,660 m. Within the next 30 months the project is to be made
            ready for construction and then sold to a property developer.
          </div>
        </div>

        <div className={styles.aboutInfo}>
          <div className={styles.title}>Total living space</div>
          <div className={styles.text}>
            According to current planning, it is expected that 36 residential
            units with a total living space of 2,257 sqm and a weighted total
            usable space of 2,217 sqm can be biult on the property.
          </div>
        </div>

        <div className={styles.aboutInfo}>
          <div className={styles.title}>Private garden areas</div>
          <div className={styles.text}>
            The size of the efficiently cut residential units is between 47 and
            110m. In addition, there are many terraces of up to 15 m and private
            garden areas of up to 208 m for all ground floor apartments. In
            addition, a children’s playground.
          </div>
        </div>
      </div>
    )
  }

  function getFileNameFromUrl(url) {
    const parts = url.split('/')
    return parts[parts.length - 1]
  }

  const FinancialsSection = () => {
    const data = {
      header: 'Project information',
      data: [
        {
          title: 'Annual return',
          value: tokenSale.additional_info.projected_annual_return,
        },
        {
          title: 'Rental Yield',
          value: tokenSale.additional_info.projected_rental_yield,
        },
        {
          title: 'Rental Yield',
          value: tokenSale.additional_info.rental_yield,
        },
      ],
    }
    return (
      <div className={styles.financialsSection}>
        <DataCardUi section={data} />
      </div>
    )
  }

  const DocumentsSection = () => {
    return (
      <div className={styles.documentsSection}>
        {tokenSale.documents.map((doc) => (
          <a key={doc.url} href={doc.url} target="_blank" className={styles.documentWrapper} rel="noreferrer">
            <div className={styles.title}>
              <img src={DocumentIcon} alt="" />
              <p>{getFileNameFromUrl(doc.url)}</p>
            </div>
            <img src={DownloadIcon} alt="" />
          </a>
        ))}
      </div>
    )
  }

  const TimelineSection = () => {
    return <div className={styles.timelineSection}></div>
  }

  const tabs = [
    {
      label: 'Overview',
      content: <OverviewSection />,
    },
    {
      label: 'Financials',
      content: <FinancialsSection />,
    },
    {
      label: 'Documents',
      content: <DocumentsSection />,
    },
    {
      label: 'Project Timeline',
      content: <TimelineSection />,
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.breadcrumb}>
        <NavLink to="/marketplace">
          <p>Marketplace</p>
        </NavLink>

        <img src={ArrowRightIcon} alt="arrow" />
        <p className={styles.disabledLink}>{tokenSale.title}</p>
      </div>
      <h3>{tokenSale.title}</h3>
      <div className={styles.location}>
        <img src={LocationIcon} alt="location" />
        <p>
          {`${tokenSale.address.address}, ${tokenSale.address.city}, ${tokenSale.address.country}`}
        </p>
      </div>
      {tokenSale.token_info.musharakah_allowed && !tokenSale.token_info.selling_musharakah ? (
        <div className={styles.musharakahBanner}>
          This property is a Musharakah token sale. When purchasing tokens, you accept
          {' '}
          {' '}
          <a href="#">the terms of Musharakah logic</a>
        </div>
      ) : null}
      {tokenSale.token_info.selling_musharakah ? (
        <div className={styles.musharakahBanner}>
          This property is currently being sold by a previous borrower. You can buy his share and then buy tokens from investors.
          {' '}
          {' '}
          <a href="#">Read more</a>
        </div>
      ) : null}
      <ImgCoverUi thumbnail={tokenSale.thumbnail} images={tokenSale.images} />
      <div className={styles.mainInfo}>
        <div className={styles.leftSection}>
          <DetailsTabsUi tabs={tabs} />
        </div>
        <div className={styles.rightSection}>
          <a target='_blank' href={`https://explorer-testnet.edifice.network/address/${tokenSale.token_info.contract_address}`} className={styles.scInfo} rel="noreferrer">
            <div className={styles.scIconWrapper}><img src={ScIcon} alt="" /></div>
            <div className={styles.scDesc}>

              {tokenSale.token_info.contract_address}

              {/* {formatBlockchainAddress(tokenSale.token_info.contract_address)} */}

            </div>
          </a>
          {isCurrentUserBorrower && <div className={styles.warning}>You cannot buy a share from yourself</div>}
          <div className={styles.buyTokenModal}>
            <div className={styles.tokenPrice}>
              <p className={styles.label}>Token price:</p>
              <p className={styles.amount}>
                {`1 ${tokenSale.token_info.symbol} = ${formatNative(tokenSale.token_info.price)} DNR`}
              </p>
            </div>
            {tokenSale.token_info.selling_musharakah && <div className={styles.tokenPrice}>
              <p className={styles.label}>Required amount for purchase</p>
              <p className={styles.amount}>
                {`${formatNumber(convertTokensToDNR(borrowerTokensLeft, price), 2, 18)} DNR`}
              </p>
            </div>}

            {tokenSale.token_info.selling_musharakah && <div className={styles.tokenPrice}>
              <p className={styles.label}>Your share of tokens</p>
              <p className={styles.amount}>
                {`${formatNative(borrowerTokensLeft)} ${tokenSale.token_info.symbol}`}
              </p>
            </div>}

            {!tokenSale.token_info.selling_musharakah && <p className={styles.availableTokens}>
              {`Available ${formatNative(availableAmount)} tokens`}
            </p>}

            {tokenSale.token_info.selling_musharakah && <p className={styles.availableTokens}>
              {`Total tokens: ${formatNative(tokenSale.token_info.total_supply)} tokens`}
            </p>}

            {tokenSale.token_info.selling_musharakah && !isCurrentUserBorrower ? (
              <div onClick={() => toggleModalBuyShare(false)} className={styles.buyBtn}>
                {'Become an owner'}
              </div>
            ) : null}

            {tokenSale.status === 'active' && isUserAuthorized ? (
              <div onClick={() => toggleModal(false)} className={styles.buyBtn}>
                {'BUY TOKEN'}
              </div>
            ) : null}
          </div>
          {(tokenSale.status === 'active' && isUserAuthorized && tokenSale.token_info.musharakah_allowed && !tokenSale.token_info.is_musharakah) ? (
            <div onClick={() => toggleModal(true)} className={styles.becomeOwnerBtn}>
              {'BECOME AN OWNER'}
            </div>
          ) : null}
        </div>
      </div>
      {tokenSale.status === 'active' ? (
        <div className={styles.mobileBottom}>
          <div className={styles.mtokenPrice}>
            <p className={styles.mlabel}>
              Token price:
              <span className={styles.mamount}>{` 1 ${tokenSale.token_info.symbol} = ${formatNative(tokenSale.token_info.price)} DNR`}</span>
            </p>
          </div>
          {isUserAuthorized && <div className={styles.mActionBtns}>
            <div onClick={() => toggleModal(false)} className={styles.mbuyBtn}>
              {'BUY TOKEN'}
            </div>
            <div onClick={() => toggleModal(true)} className={styles.mBecomeOwnerBtn}>
              {'BECOME A OWNER'}
            </div>
          </div>}

        </div>
      ) : null}

      <ModalUi
        isOpen={isBuyShareModalOpen}
        close={toggleModalBuyShare}
        header={'Buy your share ' + tokenSale.token_info.symbol + ' token'}
      >
        <BuyShareMusharakahForm tokenSale={tokenSale} onClose={toggleModalBuyShare} />
      </ModalUi>

      <ModalUi
        isOpen={isModalOpen.isOpen}
        close={() => toggleModal(false)}
        header={'Buy ' + tokenSale.token_info.symbol + ' token'}
      >
        <BuyTokenForm tokenInfo={tokenSale.token_info} isRequestOwnerPurchase={isModalOpen.isRequestOwnerPurchase} />
      </ModalUi>
    </div>
  )
}

export default TokenSale
