import styles from './styles.module.scss'
import Image2 from '../../assets/images/image-2-landing.png'
import Image3 from '../../assets/images/image-3-landing.png'

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="504"
      height="283"
      src={`https://www.youtube.com/embed/${embedId}`}
      title="EdificeX - Democratizing Home Ownership"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  </div>
)

const Landing = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.hero}>
        <div className={styles.heroContent}>
          <div className={styles.textWrapper}>
            <h1>Democratizing Real Estate Ownership and Investment</h1>
            <p className={styles.description}>
              A marketplace for homebuyers and investors.
            </p>
            <div className={styles.btnsWrapper}>
              <a href="/marketplace" className={styles.ctaBtn}>
                View our pre-launch demo marketplace
              </a>
              <a
                href="https://www.youtube.com/watch?v=mki6Aim22y8"
                target="_blank"
                className={styles.linkBtn}
                rel="noreferrer"
              >
                How it works →
              </a>
            </div>
          </div>
          <div className={styles.videoWrapper}>
            <div className="video">
              <YoutubeEmbed embedId="mki6Aim22y8?si=9olOV9oUtZXRRhcq" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionImgWrapper}>
          <img src={Image2} alt="" />
        </div>
        <div className={styles.sectionTxtWrapper}>
          <div className={styles.txtHeader}>
            Investment in Real estate should not be for a select few
          </div>
          <div className={styles.txtContent}>
            Our real estate investment and home ownership solutions have been
            designed to remove barriers to entry for anyone on the globe looking
            for long-term wealth generation.
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.fullWidth}>
          <div className={styles.fullHeader}>
            You don’t need to be an expert
          </div>
          <div className={styles.fullText}>
            That’s why our marketplace is here. It makes every step simple for
            you to effortlessly invest in a fraction of real estate. Once you
            buy a fraction, sit back and be on the path to maximizing wealth
            through rental income and property appreciation.
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.sectionTxtWrapper}>
          <div className={styles.txtHeader}>
            Being able to buy a home should be an option for everyone
          </div>
          <div className={styles.txtContent}>
            Basically, you don’t have to deal with the current cumbersome and
            unfair process. That is why we believe EdificeX is “Where ethics &
            profit meet”
          </div>
        </div>
        <div className={styles.sectionImgWrapper}>
          <img src={Image3} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Landing