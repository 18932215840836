import styles from './styles.module.scss'

const DataCardUi = ({ section }) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.header}>{section.header}</p>
      {section.data.map((row, index) => (
        <div className={styles.content} key={index}>
          <p className={styles.title}>{row.title}</p>
          <p className={styles.value}>
            {row.value}
            %
          </p>
        </div>
      ))}
    </div>
  )
}

export default DataCardUi
