import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useDispatch } from 'react-redux'

import { ConnectionType, getConnections, networkConnection } from 'web3/connection'
import { ErrorCode } from 'web3/connection/utils'
import { isSupportedChain } from 'web3/utils'
import { updateSelectedWallet } from 'state/wallet/reducer'

import Option from './Option'

import styles from './styles.module.scss'

function didUserReject(connection, error) {
  return (
    error?.code === ErrorCode.USER_REJECTED_REQUEST ||
    (connection.type === ConnectionType.WALLET_CONNECT && error?.toString?.() === ErrorCode.WC_MODAL_CLOSED) ||
    (connection.type === ConnectionType.WALLET_CONNECT && error?.toString?.() === ErrorCode.WC_MODAL_CONNECTION_RESET) ||
    (connection.type === ConnectionType.COINBASE_WALLET && error?.toString?.() === ErrorCode.CB_REJECTED_REQUEST)
  )
}

const WalletModal = ({ onClose }) => {
  const dispatch = useDispatch()

  const { connector, chainId } = useWeb3React()
  const [pendingConnection, setPendingConnection] = useState()
  const [, setPendingError] = useState()

  const connections = getConnections()

  useEffect(() => {
    return () => setPendingError(undefined)
  }, [setPendingError])

  useEffect(() => {
    if (chainId && isSupportedChain(chainId) && connector !== networkConnection.connector) {
      networkConnection.connector.activate(chainId)
    }
  }, [chainId, connector])

  const tryActivation = useCallback(async connection => {
    if (connection.overrideActivate?.()) return

    try {
      setPendingConnection(connection)
      setPendingError(undefined)

      await connection.connector.activate()
      dispatch(updateSelectedWallet(connection.type))
      onClose()
      setPendingConnection(undefined)
    } catch(error) {
      console.log(error)
      if (didUserReject(connection, error)) {
        setPendingConnection(undefined)
      } else if (error?.code !== ErrorCode.MM_ALREADY_PENDING) {
        console.debug(`web3-react conection error: ${error}`)
        setPendingError(error.message)
      }
    }
  }, [dispatch, setPendingError, onClose])

  return (
    <div className={styles.body}>
      <div className={styles['wallets-grid']}>
        {connections.filter(c => c.shouldDisplay()).map(connection => (
          <Option
            key={connection.getName()}
            connection={connection}
            activate={() => tryActivation(connection)}
            pendingConnectionType={pendingConnection?.type}
          />
        ))}
      </div>
    </div>
  )
}

export default WalletModal