import TutorialCard from 'components/UI/TutorialCard'

const FailedStatus = () => (
  <TutorialCard>
    <h4>Your Identity Verification failed</h4>
    <p>
      Please contact support
    </p>
  </TutorialCard>
)

export default FailedStatus
