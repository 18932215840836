import { useCallback } from 'react'

import { initializeConnector } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { Network } from '@web3-react/network'
import { MetaMask } from '@web3-react/metamask'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'

import METAMASK_ICON_URL from 'assets/images/wallets/metamask.svg'
import GNOSIS_ICON_URL from 'assets/images/wallets/gnosis.png'
import WALLET_CONNECT_ICON_URL from 'assets/images/wallets/walletConnectIcon.svg'
import COINBASE_ICON_URL from 'assets/images/wallets/coinbaseWalletIcon.svg'
import INJECTED_LIGHT_ICON_URL from 'assets/images/wallets/browser-wallet-light.svg'

import { isMobile } from 'utils/userAgent'

import { RPC_PROVIDERS } from '../providers'
import { CHAINS, DEFAULT_CHAIN_ID } from '../chains'

import { WalletConnectPopup } from './WalletConnectV2'

import { getIsInjected, getIsMetaMaskWallet, getIsCoinbaseWallet } from './utils'

export const ConnectionType = {
  INJECTED: 'INJECTED',
  NETWORD: 'NETWORK',
  COINBASE_WALLET: 'COINBASE_WALLET',
  WALLET_CONNECT: 'WALLET_CONNECT',
  GNOSIS_SAFE: 'GNOSIS_SAFE',
}

function onError(error) {
  console.debug(`web3-react error: ${error}`)
}

const [web3Network, web3NetworkHooks] = initializeConnector(
  actions => new Network({ actions, urlMap: RPC_PROVIDERS, defaultChainId: DEFAULT_CHAIN_ID }),
)
export const networkConnection = {
  getName: () => 'Network',
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK,
  getIcon: () => '',
  shouldDisplay: () => false,
}

const getIsCoinbaseWalletBrowser = () => isMobile && getIsCoinbaseWallet()
const getIsMetaMaskBrowser = () => isMobile && getIsMetaMaskWallet()
const getIsInjectedMobileBrowser = () => getIsCoinbaseWalletBrowser() || getIsMetaMaskBrowser()

const getShouldAdvertiseMetaMask = () => !getIsMetaMaskWallet() && !isMobile && (!getIsInjected() || getIsCoinbaseWallet())
const getIsGenericInjector = () => getIsInjected() && !getIsMetaMaskWallet() && !getIsCoinbaseWallet()

const [web3Injected, web3InjectedHooks] = initializeConnector(
  actions => new MetaMask({ actions, onError }),
)
export const injectedConnection = {
  getName: () => (getIsGenericInjector() ? 'Browser Wallet' : 'MetaMask'),
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED,
  getIcon: () => (getIsGenericInjector() ? INJECTED_LIGHT_ICON_URL : METAMASK_ICON_URL),
  shouldDisplay: () => getIsMetaMaskWallet() || getShouldAdvertiseMetaMask() || getIsGenericInjector(),
  overrideActivate: () => {
    if (getShouldAdvertiseMetaMask()) {
      window?.open('https://metamask.io/', 'inst_metamask')
      return true
    }
    return false
  },
}

const [web3GnosisSafe, web3GnosisSafeHooks] = initializeConnector(actions => new GnosisSafe({ actions }))
export const gnosisSafeConnection = {
  getName: () => 'Gnosis Safe',
  connector: web3GnosisSafe,
  hooks: web3GnosisSafeHooks,
  type: ConnectionType.GNOSIS_SAFE,
  getIcon: () => GNOSIS_ICON_URL,
  shouldDisplay: () => false,
}

const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector(actions => new WalletConnectPopup({ actions, onError }))
export const walletConnectConnection = {
  getName: () => 'WalletConnect',
  connector: web3WalletConnect,
  hooks: web3WalletConnectHooks,
  type: ConnectionType.WALLET_CONNECT,
  getIcon: () => WALLET_CONNECT_ICON_URL,
  shouldDisplay: () => !getIsInjectedMobileBrowser(),
}

const [web3CoinbaseWallet, web3CoinbaseWalletHooks] = initializeConnector(
  actions =>
    new CoinbaseWallet({
      actions,
      options: {
        url: CHAINS[DEFAULT_CHAIN_ID].urls[0],
        appName: 'Bunyan',
      },
      onError,
    }),
)
const coinbaseWalletConnection = {
  getName: () => 'Coinbase Wallet',
  connector: web3CoinbaseWallet,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.COINBASE_WALLET,
  getIcon: () => COINBASE_ICON_URL,
  shouldDisplay: () => Boolean((isMobile && !getIsInjectedMobileBrowser()) || !isMobile || getIsCoinbaseWalletBrowser()),
  overrideActivate: () => {
    if (isMobile && !getIsInjectedMobileBrowser()) {
      globalThis.window?.open('https://go.cb-w.com/mtUDhEZPy1', 'cbwallet')
      return true
    }
    return false
  },
}

export function getConnections() {
  return [
    injectedConnection,
    networkConnection,
    walletConnectConnection,
    coinbaseWalletConnection,
    gnosisSafeConnection,
  ]
}

export function useGetConnection() {
  return useCallback(c => {
    if (c instanceof Connector) {
      const connection = getConnections().find(connection => connection.connector === c)
      if (!connection) {
        throw new Error('unsupported connector')
      }
      return connection
    }

    switch (c) {
    case ConnectionType.INJECTED:
      return injectedConnection
    case ConnectionType.NETWORK:
      return networkConnection
    case ConnectionType.COINBASE_WALLET:
      return coinbaseWalletConnection
    case ConnectionType.GNOSIS_SAFE:
      return gnosisSafeConnection
    case ConnectionType.WALLET_CONNECT:
      return walletConnectConnection
    }
  }, [])
}
