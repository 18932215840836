import { useState, useEffect, useCallback } from 'react'

import TokenSaleService from 'API/TokenSaleService'

import { useSetSellingMusharakah } from 'hooks/useRealEstateToken'

import { useUserDetails } from 'state/user/hooks'

// import GlobalPreloader from 'components/UI/GlobalPreloader'
import StatsCard from 'components/StatsCard'
import ModalUi from 'components/UI/ModalUi'
import BuyBackMusharakahForm from 'components/BuyBackMusharakahForm'
import DepositTransaction from 'components/DepositTransaction'

import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'
import SellShareMusharakahForm from 'components/SellShareMusharakahForm'
import GlobalPreloader from 'components/UI/GlobalPreloader'

const MusharakahPaymentsItem = ({tokenSale}) => {
  const userDetails = useUserDetails()
  const { onSetSellingMusharakah, loading: processing } = useSetSellingMusharakah(tokenSale.token_info.contract_address, userDetails?.eth_address)

  // const { onDepositMusharakah, loading: processing } = useDepositMusharakah(tokenSale.token_info.contract_address, user.eth_address)

  const [depositTransactions, setDepositTransactions] = useState([])
  const [isRedeemModalOpen, setIsRedeemModalOpen] = useState(false)
  const [isSellShareModalOpen, setIsSellShareModalOpen] = useState(false)

  useEffect(() => {
    async function fetchDepositTransactions() {
      if (!tokenSale?.id) {
        return
      }

      const txs = await TokenSaleService.fetchDepositMusharakahTransactions(tokenSale.id)
      setDepositTransactions(txs)
    }

    fetchDepositTransactions()
  }, [tokenSale?.id])

  const toggleModalRedeem = () => setIsRedeemModalOpen(!isRedeemModalOpen)
  const toggleModalSellShare = () => setIsSellShareModalOpen(!isSellShareModalOpen)

  const handleCancelSellShare = useCallback(async () => {
    if (processing) {
      return
    }
    try {
      await onSetSellingMusharakah(!tokenSale.token_info.selling_musharakah)
    } catch (error) {
      console.error(error)
    }
  }, [
    tokenSale.token_info.selling_musharakah,
    processing,
    onSetSellingMusharakah,
  ])

  if (!tokenSale) {
    return (
      <div className={styles.wrapper}>
        You are not participating in any Musharakah sales
      </div>
    )
  }

  return (
    <div className={styles.wrapper} key={tokenSale.id}>
      {processing && <GlobalPreloader />}
      {
        tokenSale.status === 'active' && <div className={styles.tokenSaleInProgress}><p className={styles.overlayContent}>The token sale is not over yet</p></div>
      }

      {
        tokenSale.token_info.is_musharakah_paid && <div className={styles.tokenSaleInProgress}><p className={styles.overlayContent}>Musharakah is completely fulfilled. All tokens have been purchased</p></div>
      }

      <div className={styles.statsSection}>
        <StatsCard title="Total tokens" amount={`${formatNative(tokenSale.token_info.total_supply)} ${tokenSale.token_info.symbol}`} />
        <StatsCard title="Total of your tokens" amount={`${formatNative(tokenSale.token_info.total_supply - tokenSale.token_info.musharakah_tokens_left)} ${tokenSale.token_info.symbol}`} />
        <StatsCard title="Tokens left to redeem" amount={`${formatNative(tokenSale.token_info.musharakah_tokens_left)} ${tokenSale.token_info.symbol}`} />
      </div>
      <div className={styles.propertyInfo}>
        <div className={styles.leftSection}>
          <div className={styles.thumbWrapper}>
            <img src={tokenSale.thumbnail?.url} alt="" />
          </div>
          <div className={styles.tokensaleInfo}>
            <p className={styles.idTxt}>{`ID: ${tokenSale.id}`}</p>
            <p className={styles.titleTxt}>{tokenSale.title}</p>
            <p className={styles.adddressTxt}>{`${tokenSale.address.address}, ${tokenSale.address.city}, ${tokenSale.address.country}`}</p>
          </div>
        </div>
        <div className={styles.rightSection}>
          <p className={styles.rentAmount}>
            <span className={styles.description}>{`Price for 1 ${tokenSale.token_info.symbol} token:`}</span>
            {` ${formatNative(tokenSale.token_info.price)} DNR`}
          </p>
          {!tokenSale.token_info.is_musharakah_paid && !tokenSale.token_info.selling_musharakah && <button onClick={toggleModalRedeem} className={styles.payBtn}>redeem tokens</button>}
          {tokenSale.token_info.selling_musharakah ? (
            <button disabled={processing} onClick={handleCancelSellShare} className={styles.sellShare}>Cancel shares sell</button>
          ) : (
            <button onClick={toggleModalSellShare} className={styles.sellShare}>Sell your share</button>
          )}
        </div>
      </div>
      <div className={styles.transactionsSection}>
        {depositTransactions.length > 0 ?(
          depositTransactions.map(tx => (
            <DepositTransaction key={tx.hash} transaction={tx} isMusharakah />
          ))
        ) : (
          <p className={styles.noneTransactions}>You do not have any payment. Make your first payment to see the transaction</p>
        )}
      </div>
      <ModalUi
        isOpen={isRedeemModalOpen}
        close={toggleModalRedeem}
        header={'BuyBack ' + tokenSale.token_info.symbol + ' token'}
      >
        <BuyBackMusharakahForm tokenSale={tokenSale} />
      </ModalUi>
      <ModalUi
        isOpen={isSellShareModalOpen}
        close={toggleModalSellShare}
        header={'Sell your share ' + tokenSale.token_info.symbol + ' token'}
      >
        <SellShareMusharakahForm tokenSale={tokenSale} onClose={toggleModalSellShare} />
      </ModalUi>
    </div>
  )
}

export default MusharakahPaymentsItem