import { useState, useCallback, useMemo } from 'react'
import { useLoaderData, useRevalidator } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { formatEther, parseEther } from 'ethers'
import cx from 'classnames'

import { useBuyOrder } from 'hooks/useExchange'
import { useUserBalanceNative } from 'state/user/hooks'
import { useIsCorrectAddress, useIsWalletConnected } from 'state/user/hooks'
import { useActiveNetwork } from 'state/network/hooks'

import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'
import ErrorBuyBanner from 'components/UI/ErrorBuyBanner'
import GlobalPreloader from 'components/UI/GlobalPreloader'
import SuccessBuyBanner from 'components/UI/SuccessBuyBanner'

const P2pForm = () => {
  const order = useLoaderData()
  const revalidator = useRevalidator()
  const { loading, onBuyOrder } = useBuyOrder(order.id)

  const balance = useUserBalanceNative()
  const [currencyAmount, setCurrencyAmount] = useState('')
  const [tokenAmount, setTokenAmount] = useState('')
  const [formError, setFormError] = useState('')

  const [successPurchase, setSuccessPurchase] = useState(false)

  const activeNetwork = useActiveNetwork()
  const isCorrectAddress = useIsCorrectAddress()
  const isWalletConnected = useIsWalletConnected()

  const setMaxAmount = useCallback(() => {
    calculatePayCurrency(formatEther(balance))
  }, [balance])

  const setAvailableTokens = useCallback(() => {
    calculateTokenCurrency(formatEther(order.available_amount))
  }, [order.available_amount])

  const calculatePayCurrency = useCallback((payAmount) => {
    if (!payAmount) {
      setTokenAmount('')
      setCurrencyAmount('')
      return
    }

    setCurrencyAmount(payAmount)
    setTokenAmount((parseFloat(payAmount) / parseFloat(formatEther(order.token_price))).toString())
  }, [order.token_price])

  const calculateTokenCurrency = useCallback((tokenAmount) => {
    if (!tokenAmount) {
      setTokenAmount('')
      setCurrencyAmount('')
      return
    }

    setTokenAmount(tokenAmount)
    setCurrencyAmount((parseFloat(tokenAmount) * parseFloat(formatEther(order.token_price))).toString())
  }, [order.token_price])

  const canBuy = useMemo(() => {
    setFormError('')

    if (balance == 0 && isWalletConnected) setFormError('You do not have DNR native currency in your account to make the minimum purchase. Top up your balance before making a purchase')

    if (currencyAmount && (parseEther(currencyAmount) > BigInt(balance))) setFormError('The specified purchase amount exceeds your balance')
    if (tokenAmount && (parseEther(tokenAmount) > BigInt(order.available_amount))) setFormError(`You cannot buy more tokens than are in the order. Pay attention to the maximum value = ${formatEther(order.available_amount)} ${order.token_symbol}`)

    if (
      !currencyAmount ||
      !tokenAmount ||
      parseEther(currencyAmount) > BigInt(balance) ||
      parseEther(tokenAmount) > BigInt(order.available_amount)
    ) {
      return false
    }

    return true
  }, [currencyAmount, tokenAmount, balance, order.available_amount])

  const handleBuy = useCallback(async () => {
    setFormError('')

    if (!canBuy) {
      return
    }

    if (tokenAmount && tokenAmount == 0) {
      setFormError('Cannot be less than or equal to 0')
      return
    }

    if (currencyAmount && currencyAmount == 0) {
      setFormError('Cannot be less than or equal to 0')
      return
    }

    try {
      const reciept = await onBuyOrder(currencyAmount)

      if (reciept?.status === 1) {
      // success transaction
        setSuccessPurchase(true)
        console.log(reciept)
      }
      setCurrencyAmount('')
      setTokenAmount('')
      revalidator.revalidate()
    } catch (error) {
      console.log(error)
    }
  }, [currencyAmount, canBuy, revalidator, onBuyOrder])

  const handleCurrencyAmountChange = e => {
    let { value } = e.target

    if (value === '.') {
      value = '0.'
    } else if (!/^\d*\.?\d*$/.test(value)) {
      return
    }

    calculatePayCurrency(value)
  }

  const handleTokenAmountChange = e => {
    let { value } = e.target

    if (value === '.') {
      value = '0.'
    } else if (!/^\d*\.?\d*$/.test(value)) {
      return
    }

    calculateTokenCurrency(value)
  }

  const renderFirstError = () => {
    if (!isWalletConnected) {
      return <ErrorBuyBanner label="Wallet not connected" desc="" />
    }
    if (!isCorrectAddress) {
      return (
        <ErrorBuyBanner
          label="Your wallet address does not match your account"
          desc="When registering, each user adds his wallet address and only with the help of it can he perform all financial transactions. Please switch to your wallet address that you specified when registering in your Metamask."
        />
      )
    }
    if (!activeNetwork) {
      return (
        <ErrorBuyBanner
          label="You are using the wrong network"
          desc="Our application uses Edifice's own blockchain. Please switch to the Edifice network in your Metamask settings."
        />
      )
    }
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.backTolink}>
        <NavLink to="/p2p-exchange" className={styles.backTolink}>
          Back to P2P exchange page
        </NavLink>
      </div>
      <div className={styles.buyForm}>
        {loading && <GlobalPreloader />}
        { successPurchase && <SuccessBuyBanner text="The purchase of tokens was successful. They have been sent your wallet. You can check your balance both in Metamask and on the Wallet page" /> }
        <h4>
          Buying a
          {' '}
          <span>{order.token_symbol}</span>
          {' '}
          token
        </h4>
        <p className={styles.description}>
          You buy a token from the seller. The entire process occurs
          automatically and does not require anything other than the DNR
          currency.
        </p>
        <div className={styles.priceBanner}>
          <p className={styles.price}>
            {`1 ${order.token_symbol} = ${formatNative(order.token_price)} DNR`}
          </p>
          <p className={styles.description}>
            This price was indicated by the seller
          </p>
        </div>
        {renderFirstError()}
        {formError && <ErrorBuyBanner label="Error" desc={formError} />}

        {order.status === 'active' ? (
          <div className={styles.formWrapper}>
            <div className={styles.customInputWrapper}>
              <p className={styles.label}>You pay</p>
              <p className={styles.actionLabel}>
                Your balance:
                {' '}
                <span onClick={setMaxAmount} className={styles.maxBalance}>
                  {`${formatNative(balance)} DNR`}
                </span>
              </p>
              <input
                type="text"
                className={styles.customInput}
                value={currencyAmount}
                // pattern="^([0-9]+(?:[.,][0-9]*)?)$"
                inputMode="decimal"
                onChange={handleCurrencyAmountChange}
              />
            </div>
            <div className={styles.customInputWrapper}>
              <p className={styles.label}>You receive</p>
              <p className={styles.actionLabel}>
                Available:
                {' '}
                <span
                  onClick={setAvailableTokens}
                  className={styles.availableTokens}
                >
                  {`${formatEther(order.available_amount)} ${order.token_symbol}`}
                </span>
              </p>
              <input
                type="text"
                className={styles.customInput}
                value={tokenAmount}
                // pattern="^([0-9]+(?:[.,][0-9]*)?)$"
                inputMode="decimal"
                onChange={handleTokenAmountChange}
              />
            </div>
            <div className={styles.actionBtnWrapper}>
              <div className={cx(styles.btn, {[styles.disabled]: !canBuy || loading})} onClick={handleBuy}>
                {`Buy ${order.token_symbol} token`}
              </div>
            </div>
            {
              !canBuy && <p className={styles.feeInfo}>Fill in all fields to buy from order</p>
            }
          </div>
        ) : (
          <p className={styles.inactive}>
            Order inactive
          </p>
        )}
      </div>
    </div>
  )
}

export default P2pForm
