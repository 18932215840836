export const getIsInjected = () => Boolean(window?.ethereum)

const allNonMetaMaskFlags = ['isRabby', 'isBraveWallet', 'isTrustWallet', 'isLedgerConnect']
export const getIsMetaMaskWallet = () => Boolean(window?.ethereum?.isMetaMask && !allNonMetaMaskFlags.some(flag => window?.ethereum?.[flag]))

export const getIsCoinbaseWallet = () => Boolean(window?.ethereum?.isCoinbaseWallet)

export const ErrorCode = {
  USER_REJECTED_REQUEST: 4001,
  UNAUTHORIZED: 4100,
  UNSUPPORTED_METHOD: 4200,
  DISCONNECTED: 4900,
  CHAIN_DISCONNECTED: 4901,

  CHAIN_NOT_ADDED: 4902,
  MM_ALREADY_PENDING: -32002,

  WC_MODAL_CLOSED: 'Error: User closed modal',
  WC_MODAL_CONNECTION_RESET: 'Error: Connection request reset. Please try again.',
  CB_REJECTED_REQUEST: 'Error: User denied account authorization',
}
