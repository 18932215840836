import { useCallback } from 'react'

import { useCancelOrder, useCancelSellSharesOrder } from 'hooks/useExchange'

import OrderStatusUi from 'components/UI/OrderStatusUi'

import { formatNative} from 'utils/formatNumber'

import styles from './styles.module.scss'

const UserOrderItem = ({ order, type=null }) => {
  const { onCancelOrder, loading: processingCancelOrder } = useCancelOrder(order.order_id)
  const { onCancelSellSharesOrder, loading: processingCancelShareOrder } = useCancelSellSharesOrder(order.token_info.contract_address)

  // export function useCancelSellSharesOrder(tokenAddress) {
  //   const contract = useRealEstateTokenContract(tokenAddress)
  //   const { loading, fetchWithCatchTxError } = useCatchTxError()

  //   const onCancelSellSharesOrder = useCallback(async () => {
  //     if (!contract) {
  //       return
  //     }

  //     const receipt = await fetchWithCatchTxError(() => {
  //       return contract.cancelSellSharesOrder()
  //     }, {
  //       message: 'Canceling sell shares order',
  //     })

  //     return receipt
  //   }, [contract, fetchWithCatchTxError])

  //   return { loading, onCancelSellSharesOrder }
  // }

  const handleCancelOrder = useCallback(async () => {
    try {
      await onCancelOrder()
    } catch (error) {
      console.log(error)
    }
  }, [onCancelOrder])

  const handleCancelShareOrder = useCallback(async () => {
    try {
      await onCancelSellSharesOrder()
    } catch (error) {
      console.log(error)
    }
  }, [onCancelSellSharesOrder])

  //order.type = ["p2p", "borrower_sell_share", "investor_ownership"]

  return (
    <div className={`${styles.wrapper} ${order.status === 'completed' || order.status === 'canceled' ? styles.orderDone : ''}`}>
      <div className={styles.leftSection}>
        <div className={styles.sellerInfo}>
          <OrderStatusUi status={order.status} date={order.created_at} type={type} />
        </div>
        <div className={styles.priceInfo}>
          {formatNative((type === 'investor_ownership') ? order.price : order.token_price)}
          {' '}
          <span>DNR</span>
        </div>
        <div className={styles.limitsInfo}>
          <div className={styles.available}>
            <p className={styles.label}>Available:</p>
            <p className={styles.value}>
              {formatNative(order.available_amount)}
              {' '}
              <span>{order.token_info.symbol}</span>
            </p>
          </div>
          {order.type !== 'borrower_sell_share' && <div className={styles.limits}>
            <p className={styles.label}>Filled:</p>
            <p className={styles.value}>
              {`${formatNative(order.fulfilled_amount)} `}
              /
              {' '}
              {formatNative(parseInt(order.available_amount) + parseInt(order.fulfilled_amount))}
              <span>
                {' '}
                {order.token_info.symbol}
              </span>
            </p>
          </div>}

        </div>
      </div>
      {
        order.status === 'active' && <div className={styles.rightSection}>
          <button disabled={(type === 'investor_ownership') ? processingCancelShareOrder : processingCancelOrder} className={styles.actionBtn} onClick={(type === 'investor_ownership') ? handleCancelShareOrder : handleCancelOrder}>
            Cancel order
          </button>
        </div>
      }

    </div>
  )
}

export default UserOrderItem
