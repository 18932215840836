import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'

import { CHAINS } from './chains'

export function isAddress(value) {
  try {
    return getAddress(value.toLowerCase())
  } catch {
    return false
  }
}

function getSigner(provider, account) {
  return provider.getSigner(account).connectUnchecked()
}

function getProviderOrSigner(provider, account) {
  return account ? getSigner(provider, account) : provider
}

export function getContract(address, ABI, provider, account) {
  if (!isAddress(address) || address === AddressZero) {
    throw new Error(`Invalid 'address' parameter: '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(provider, account))
}

export function isSupportedChain(chainId) {
  return !!chainId && CHAINS[chainId]?.available
}
