import MusharakahPaymentsItem from 'components/MusharakahPaymentsItem'

import {
  // useUserDetails,
  useUserMusharakahTokenSales,
  useUpdateUserMusharakahTokenSales,
} from 'state/user/hooks'

import useInterval from 'hooks/useInterval'

const MusharakahPayments = () => {
  const updateMusharakahTokenSales = useUpdateUserMusharakahTokenSales()
  const tokenSales = useUserMusharakahTokenSales()

  useInterval(updateMusharakahTokenSales, 10000)

  return (
    tokenSales.map((tokenSale) => (<MusharakahPaymentsItem key={tokenSale.id} tokenSale={tokenSale} />))
  )
}

export default MusharakahPayments