import { useState, useCallback, useMemo } from 'react'
import cx from 'classnames'

import { useBuyMusharakah } from 'hooks/useRealEstateToken'

import { useIsCorrectAddress, useUserBalanceNative, useIsWalletConnected} from 'state/user/hooks'
import { useActiveNetwork } from 'state/network/hooks'

import GlobalPreloader from 'components/UI/GlobalPreloader'
import SuccessBuyBanner from 'components/UI/SuccessBuyBanner'
import ErrorBuyBanner from 'components/UI/ErrorBuyBanner'

import { formatNative } from 'utils/formatNumber'
import { convertTokensToDNR } from 'utils/calculate'

import styles from './styles.module.scss'

const BuyShareMusharakahForm = ({ tokenSale, onClose }) => {
  const activeNetwork = useActiveNetwork()
  const isCorrectAddress = useIsCorrectAddress()
  const balanceNative = useUserBalanceNative()
  const isWalletConnected = useIsWalletConnected()
  const { onBuyMusharakah, loading: processing } = useBuyMusharakah(tokenSale.token_info.contract_address)

  const [successPurchase, setSuccessPurchase] = useState(false)
  const [formError, setFormError] = useState('')

  const totalSupply = BigInt(tokenSale.token_info.total_supply)
  const tokensLeft = BigInt(tokenSale.token_info.musharakah_tokens_left)
  const price = BigInt(tokenSale.token_info.price)

  const amount = convertTokensToDNR(totalSupply - tokensLeft, price)

  let canBuy = useMemo(() => {
    setFormError('')

    if (amount > balanceNative) {
      setFormError('Insufficient balance to purchase tokens')
      return false
    }

    if (!isCorrectAddress || !activeNetwork) {
      return false
    }
    return true
  }, [isCorrectAddress, activeNetwork])

  const handleSellShare = useCallback(async () => {
    if (!canBuy || processing) {
      return
    }
    try {
      await onBuyMusharakah(amount)
      setSuccessPurchase(true)
      // onClose()
    } catch (error) {
      console.error(error)
    }
  }, [
    tokenSale.token_info.selling_musharakah,
    canBuy,
    processing,
    onBuyMusharakah,
    onClose,
  ])

  const renderFirstError = () => {
    if (!isWalletConnected) {
      return <ErrorBuyBanner label="Wallet not connected" desc="" />
    }
    if (!isCorrectAddress) {
      return (
        <ErrorBuyBanner
          label="Your wallet address does not match your account"
          desc="When registering, each user adds his wallet address and only with the help of it can he perform all financial transactions. Please switch to your wallet address that you specified when registering in your Metamask."
        />
      )
    }
    if (!activeNetwork) {
      return (
        <ErrorBuyBanner
          label="You are using the wrong network"
          desc="Our application uses Edifice's own blockchain. Please switch to the Edifice network in your Metamask settings."
        />
      )
    }
    return null
  }

  return (
    <div className={styles.wrapper}>
      {processing && <GlobalPreloader />}
      {successPurchase && <SuccessBuyBanner text="You have successfully completed the token sell share. The transaction will later automatically appear in the orders tab" closeLink={true} />}
      {renderFirstError()}
      <div className={styles.infoBanner}>You are going to sell all your tokens and reassign the owner of the property. Please note that during an active sell order you will not be able to redeem tokens.</div>
      <div className={styles.formInfo}>
        <p className={styles.availableInfo}>{`1 ${tokenSale.token_info.symbol} = ${formatNative(tokenSale.token_info.price)} DNR`}</p>
        <hr />
        <p className={styles.priceInfo}>{`You will buy ${formatNative(tokenSale.token_info.total_supply - tokenSale.token_info.musharakah_tokens_left)} ${tokenSale.token_info.symbol} `}</p>
        <hr />
        <p className={styles.priceInfo}>{`You have to pay ${formatNative(amount)} DNR `}</p>
      </div>

      {formError && <p className={styles.formError}>{formError}</p>}
      <div className={styles.actionBtnWrapper} onClick={handleSellShare}>
        <p className={cx(styles.btn, {[styles.disabled]: !canBuy || processing})}>
          {'Buy share'}
        </p>
      </div>
    </div>
  )
}

export default BuyShareMusharakahForm