import { useState, useCallback, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { formatEther, parseEther } from 'ethers'
import cx from 'classnames'

import {
  useTokenBalance,
  useTokenSaleSuccessed,
  useIsTokenSaleInvestor,
  useShareOrderExists,
  // useTokenExchangeAllowance,
  // useTokenExchangeApprove,
} from 'hooks/useRealEstateToken'
import { useCreateSellSharesOrder } from 'hooks/useExchange'

import { useUserSellSharesOrders } from 'state/user/hooks'
import { useIsCorrectAddress, useIsWalletConnected } from 'state/user/hooks'
import { useActiveNetwork } from 'state/network/hooks'

import ErrorBuyBanner from 'components/UI/ErrorBuyBanner'
import SuccessBuyBanner from 'components/UI/SuccessBuyBanner'
// import GlobalPreloader from 'components/UI/GlobalPreloader'

import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'
import GlobalPreloader from 'components/UI/GlobalPreloader'
import { useFilteredTokenSales } from 'hooks/useTokenSales'
import CustomSelectorUi from 'components/UI/CustomSelectorUi'

const CreateInvestorOwnershipOrder = () => {
  const { account } = useWeb3React()
  const [selectedToken, setSelectedToken] = useState('')
  const { filteredTokenSales, isLoading, error } = useFilteredTokenSales()
  const tokenBalance = useTokenBalance(selectedToken)
  const saleSuccessed = useTokenSaleSuccessed(selectedToken)
  const isInvestor = useIsTokenSaleInvestor(selectedToken)
  const { onCreateSellSharesOrder, loading: processing } = useCreateSellSharesOrder(selectedToken)

  const userSellSharesOrders = useUserSellSharesOrders()

  const shareOrderExists = useShareOrderExists(selectedToken, userSellSharesOrders)

  const activeNetwork = useActiveNetwork()
  const isCorrectAddress = useIsCorrectAddress()
  const isWalletConnected = useIsWalletConnected()

  const [tokenAmount, setTokenAmount] = useState('')
  const [tokenPrice, setTokenPrice] = useState('')

  const [formError, setFormError] = useState('')

  const [successPurchase, setSuccessPurchase] = useState(false)

  const canCreate = useMemo(() => {
    if (!tokenAmount || !tokenPrice || !isInvestor || shareOrderExists || !saleSuccessed) {
      return false
    }
    const amt = parseEther(tokenAmount)
    const price = parseEther(tokenPrice)
    if (amt === 0n || amt > parseEther(tokenBalance) || price === 0n) {
      return false
    }

    return true
  }, [tokenAmount, tokenPrice, tokenBalance, saleSuccessed])

  const getMaxAmount = useCallback(() => {
    setTokenAmount(formatEther(tokenBalance))
  }, [tokenBalance])

  const handleCreateSellSharesOrder = useCallback(async () => {
    setFormError('')
    if (!canCreate) {
      return
    }

    if (parseEther(tokenAmount) > parseEther(tokenBalance)) {
      setFormError('You have indicated an amount that exceeds your balance')
      return
    }

    try {
      await onCreateSellSharesOrder(parseEther(tokenAmount), parseEther(tokenPrice))
      setSuccessPurchase(true)
    } catch (error) {
      console.error(error)
    }
  }, [
    tokenAmount,
    tokenPrice,
    canCreate,
    processing,
    onCreateSellSharesOrder,
  ])

  const onSelectTokenSale = (tokeSale) => {
    setSelectedToken(tokeSale.token_info.contract_address)
  }

  const handleTokenAmountChange = event => {
    let { value } = event.target

    if (value === '.') {
      value = '0.'
    } else if (!/^\d*\.?\d*$/.test(value)) {
      return
    }
    setTokenAmount(value)
  }

  const handleTokenPriceChange = event => {
    let { value } = event.target
    if (value === '.') {
      value = '0.'
    } else if (!/^\d*\.?\d*$/.test(value)) {
      return
    }
    setTokenPrice(value)
  }

  const getMusharakahTokenSales = (tokenSales) => {
    const filteredTokenSales = tokenSales.filter(tokenSale =>
      tokenSale.token_info.musharakah_allowed === true && tokenSale.token_info.is_musharakah === true && tokenSale.token_info.is_musharakah_paid === false,
    )

    return filteredTokenSales
  }

  if (isLoading) return <GlobalPreloader />
  if (error) return <div>
    Error:
    {error}
  </div>

  const renderFirstError = () => {
    if (!isWalletConnected) {
      return <ErrorBuyBanner label="Wallet not connected" desc="" />
    }
    if (!isCorrectAddress) {
      return (
        <ErrorBuyBanner
          label="Your wallet address does not match your account"
          desc="When registering, each user adds his wallet address and only with the help of it can he perform all financial transactions. Please switch to your wallet address that you specified when registering in your Metamask."
        />
      )
    }
    if (!activeNetwork) {
      return (
        <ErrorBuyBanner
          label="You are using the wrong network"
          desc="Our application uses Edifice's own blockchain. Please switch to the Edifice network in your Metamask settings."
        />
      )
    }
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.backTolink}>
        <NavLink to="/wallet" className={styles.backTolink}>
          Back to Wallet page
        </NavLink>
      </div>
      <div className={styles.buyForm}>
        {processing && <GlobalPreloader />}
        { successPurchase && <SuccessBuyBanner text="The order creation was successful. You can see a list of all your orders on the wallet page in the Orders section" /> }
        <h4>Create an order for the sale of rights to Musharakah tokens</h4>
        <p className={styles.description}>
          You can create an order to sell the rights to your tokens that are participating in the active phase of the Musharakah token sale. While the order is active, your tokens will also be bought back by the borrower, and you will also receive rent.
        </p>
        {formError && <ErrorBuyBanner label="Error" desc={formError} />}
        {renderFirstError()}
        {selectedToken && saleSuccessed && !isInvestor ? (
          <ErrorBuyBanner label="You are not an investor" desc="You are not an investor" />
        ) : null}
        {selectedToken && shareOrderExists ? (
          <ErrorBuyBanner label="You have active order" desc="You cannot create more than one order of the musharakah type for transferring rights to tokens. Cancel your previous order and create a new one" />
        ) : null}
        {selectedToken && !saleSuccessed ? (
          <ErrorBuyBanner label="Token sale is not over yet" desc="You cannot create orders using tokens of real estate objects whose token sales have not ended. Wait until the end of the token sale to create an order to sell tokens." />
        ) : null}
        <div className={styles.addressBanner}>
          <p className={styles.address}>
            {account}
          </p>
          <p className={styles.description}>
            Wallet address where funds will be sent after someone buys your
            tokens
          </p>
        </div>
        {/* <div className={styles.formWrapper}>
          <div className={styles.customInputWrapper}>
            <p className={styles.label}>Select token</p>
            <select className={styles.customInput} onChange={handleSelectToken}>
              <option value="">Select token</option>
              {getMusharakahContractAddresses(filteredTokenSales).map(address => (
                <option key={address} value={address}>
                  {address}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        <div className={styles.formWrapper}>
          <div className={styles.customInputWrapper}>
            <CustomSelectorUi onSelectTokenSale={onSelectTokenSale} tokenSales={getMusharakahTokenSales(filteredTokenSales)} />
          </div>
        </div>
        {selectedToken && saleSuccessed && isInvestor ? (
          <div className={styles.formWrapper}>
            <div className={styles.customInputWrapper}>
              <p className={styles.label}>How many tokens do you want to sell?</p>
              <p className={styles.actionLabel}>
                Your balance:
                {' '}
                <span onClick={getMaxAmount} className={styles.maxBalance}>
                  {`${formatNative(tokenBalance)}`}
                </span>
              </p>
              <input
                type="text"
                className={styles.customInput}
                value={tokenAmount}
                // pattern="^([0-9]+(?:\.[0-9]*)?)$"
                inputMode="decimal"
                onChange={handleTokenAmountChange}
              />
            </div>
            <div className={styles.customInputWrapper}>
              <p className={styles.label}>At what price do you want to sell?</p>
              <p className={styles.actionLabel}>
                Specify the desired price for 1 token in DNR
              </p>
              <input
                type="text"
                className={styles.customInput}
                value={tokenPrice}
                // pattern="^([0-9]+(?:\.[0-9]*)?)$"
                inputMode="decimal"
                onChange={handleTokenPriceChange}
              />
            </div>
            <div className={styles.actionBtnWrapper}>

              <div
                className={cx(styles.btn, {[styles.disabled]: !canCreate || processing})}
                onClick={handleCreateSellSharesOrder}
              >
                Create Musharakah order
              </div>
            </div>
            {
              !canCreate && <p className={styles.feeInfo}>
                Fill in all fields to create an order
              </p>
            }
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CreateInvestorOwnershipOrder