import { useActiveOrders, useActiveSellSharesOrders } from 'hooks/useExchange'

import OrderItem from 'components/OrderItem'

import doneIcon from 'assets/images/icons/doneBadge.svg'

import styles from './styles.module.scss'

const Exchange = () => {
  const orders = useActiveOrders()
  const sellShareOrders = useActiveSellSharesOrders()

  return (
    <div className={styles.wrapper}>
      <h1>Buy and Sell real estate project tokens</h1>
      <div className={styles.banner}>
        <div className={styles.iconWrapper}>
          <img src={doneIcon} alt="" />
        </div>
        <p className={styles.textDesc}>
          Welcome to our p2p exchange, where innovation meets convenience. Here,
          all transactions are seamlessly carried out using our native digital
          currency, DNR. Our automatic transaction system, designed with user
          experience in mind, ensures that your trades are executed promptly and
          securely.
        </p>
      </div>
      <div className={styles.mainSection}>
        {/* <div className={styles.filters}>
          <input
            className={styles.filterInput}
            type="text"
            placeholder="Token name or symbol"
          />
          <input
            className={styles.filterInput}
            type="text"
            placeholder="Amount in DNR"
          />
          <input
            className={styles.filterInput}
            type="text"
            placeholder="Amount in tokens"
          />
          <div className={styles.customWrapper}>
            <input
              className={styles.customFilterInput}
              type="text"
              placeholder="Filters"
            />
            <img className={styles.inputIcon} src={arrowDownIcon} alt="" />
          </div>
        </div> */}
        <div className={styles.ordersWrapper}>
          {sellShareOrders.length > 0 ? (
            sellShareOrders.map((order) => (
              <OrderItem key={order.token_symbol} order={order} type="investor_ownership" />
            ))
          ) : (
            null
          )}
        </div>
        <div className={styles.ordersWrapper}>
          {orders.length > 0 ? (
            orders.map((order) => (
              <OrderItem key={order.id} order={order} />
            ))
          ) : (
            null
          )}
        </div>
      </div>
    </div>
  )
}

export default Exchange
