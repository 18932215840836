import { useLocation, Outlet, Navigate } from 'react-router-dom'

import { useUserDetails, useIsUserAuthorized } from 'state/user/hooks'

import MainContainerUi from '../UI/MainCointainerUi'
import Header from '../Header'
import MobileHeader from '../MobileHeader'
import Footer from '../Footer'

const Layout = () => {
  const location = useLocation()
  const isUserAuthorized = useIsUserAuthorized()
  const userDetails = useUserDetails()

  if (isUserAuthorized && !userDetails) {
    return null
  }

  if (isUserAuthorized && userDetails?.status !== 'verified' && location.pathname != '/identity-verification') {
    return (
      <Navigate
        to="/identity-verification"
        replace
      />
    )
  }

  if (isUserAuthorized && !userDetails?.eth_address && location.pathname != '/verify-eth-address' && location.pathname != '/identity-verification') {
    return (
      <Navigate
        to="/verify-eth-address"
        replace
      />
    )
  }

  return (
    <div>
      <Header />
      <MobileHeader />
      <MainContainerUi>
        <Outlet />
      </MainContainerUi>
      <Footer />
    </div>
  )
}

export default Layout
