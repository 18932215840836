import { createContext, useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'

import useIsWindowVisible from 'hooks/useIsWindowVisible'

const MISSING_PROVIDER = Symbol()
const BlockNumberContext = createContext(MISSING_PROVIDER)

function useBlockNumberContext() {
  const blockNumber = useContext(BlockNumberContext)
  if (blockNumber === MISSING_PROVIDER) {
    throw new Error('BlockNumber hooks must be wrapped in a <BlockNumberProvider>')
  }
  return blockNumber
}

export default function useBlockNumber() {
  return useBlockNumberContext().value
}

export function useFastForwardBlockNumber() {
  return useBlockNumberContext().fastForward
}

export function BlockNumberProvider({ children }) {
  const { chainId: activeChainId, provider } = useWeb3React()
  const [{ chainId, block }, setChainBlock] = useState({ chainId: activeChainId })

  const onBlock = useCallback(block => {
    setChainBlock(chainBlock => {
      if (chainBlock.chainId === activeChainId) {
        if (!chainBlock.block || chainBlock.block < block) {
          return { chainId: activeChainId, block }
        }
      }

      return chainBlock
    })
  }, [activeChainId, setChainBlock])

  const windowVisible = useIsWindowVisible()
  useEffect(() => {
    let stale = false
    if (provider && activeChainId && windowVisible) {
      setChainBlock(chainBlock => (chainBlock.chainId === activeChainId ? chainBlock : { chainId: activeChainId }))

      provider
        .getBlockNumber()
        .then(block => {
          if (!stale) onBlock(block)
        })
        .catch(error => {
          console.error(`Failed to get block number for chainId ${activeChainId}`, error)
        })

      provider.on('block', onBlock)

      return () => {
        stale = true
        provider.removeListener('block', onBlock)
      }
    }

    return void 0
  }, [activeChainId, provider, onBlock, setChainBlock, windowVisible])

  const value = useMemo(() => ({
    value: chainId === activeChainId ? block : undefined,
    fastForward: update => {
      if (block && update > block) {
        setChainBlock({ chainId: activeChainId, block: update })
      }
    },
  }), [activeChainId, block, chainId])

  return (
    <BlockNumberContext.Provider value={value}>
      {children}
    </BlockNumberContext.Provider>
  )
}
