import { useCallback } from 'react'

import useInterval from 'hooks/useInterval'

import {
  useUpdateUserDetails,
  useUpdateUserBalanceNative,
  useUpdateUserTenementTokenSales,
  useUpdateUserSellSharesOrders,
} from './hooks'

export default function Updater() {
  const updateUserDetails = useUpdateUserDetails()
  const updateUserBalanceNative = useUpdateUserBalanceNative()
  const updateUserTenementTokenSales = useUpdateUserTenementTokenSales()
  const updateUserSellSharesOrders = useUpdateUserSellSharesOrders()

  const updateData = useCallback(async () => {
    updateUserDetails(),
    updateUserBalanceNative(),
    updateUserTenementTokenSales(),
    updateUserSellSharesOrders()
  }, [
    updateUserDetails,
    updateUserBalanceNative,
    useUpdateUserTenementTokenSales,
    useUpdateUserSellSharesOrders,
  ])

  useInterval(updateData, 5000)

  return null
}
