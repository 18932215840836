import { useDepositMusharakah } from 'hooks/useRealEstateToken'
import {
  useUserDetails,
  useUserBalanceNative,
} from 'state/user/hooks'

import { parseEther } from 'ethers'
import cx from 'classnames'
import { formatNative } from 'utils/formatNumber'

import { useIsCorrectAddress, useIsWalletConnected } from 'state/user/hooks'
import { useActiveNetwork } from 'state/network/hooks'

import { useState, useMemo, useCallback } from 'react'

import GlobalPreloader from 'components/UI/GlobalPreloader'
import SuccessBuyBanner from 'components/UI/SuccessBuyBanner'
import ErrorBuyBanner from 'components/UI/ErrorBuyBanner'
import BuyTokenInput from 'components/UI/BuyTokenInput'

import styles from './styles.module.scss'

const BuyBackMusharakahForm = ({tokenSale}) => {
  const activeNetwork = useActiveNetwork()
  const isCorrectAddress = useIsCorrectAddress()
  const user = useUserDetails()
  const isWalletConnected = useIsWalletConnected()
  // const { onDepositMusharakah, loading: processing } = useDepositMusharakah(tokenSale.token_info.contract_address, user.eth_address)
  const { onDepositMusharakah, loading } = useDepositMusharakah(tokenSale.token_info.contract_address, user.eth_address)
  const balanceNative = useUserBalanceNative()

  const [dnrAmount, setDnrAmount] = useState('')
  const [successPurchase, setSuccessPurchase] = useState(false)
  const [formError, setFormError] = useState('')

  let canBuy = useMemo(() => {
    setFormError('')
    if (dnrAmount === '' || dnrAmount === '0' || loading || !isCorrectAddress || !activeNetwork) {
      return false
    }

    if (parseEther(dnrAmount) > BigInt(balanceNative)) {
      setFormError('Insufficient balance to purchase tokens')
      return false
    }
    return true
  }, [dnrAmount, loading])

  const handleBuyBackTokens = useCallback(async () => {
    if (!canBuy) {
      return
    }

    try {
      const reciept = await onDepositMusharakah(parseEther(dnrAmount))
      if (reciept.status === 1) {
        setSuccessPurchase(true)
      }
    }
    catch (error) {
      console.log(error)
    }
  }, [dnrAmount, canBuy, onDepositMusharakah])


  const setMaxDnrAmount = () =>
  {
    setDnrAmount(formatNative(balanceNative))
  }

  const handleDNRAmountChange = (event) => {
    let { value } = event.target

    if (value === '.') {
      value = '0.'
    } else if (!/^\d*\.?\d*$/.test(value)) {
      return
    }

    setDnrAmount(value)
  }

  const renderFirstError = () => {
    if (!isWalletConnected) {
      return <ErrorBuyBanner label="Wallet not connected" desc="" />
    }
    if (!isCorrectAddress) {
      return (
        <ErrorBuyBanner
          label="Your wallet address does not match your account"
          desc="When registering, each user adds his wallet address and only with the help of it can he perform all financial transactions. Please switch to your wallet address that you specified when registering in your Metamask."
        />
      )
    }
    if (!activeNetwork) {
      return (
        <ErrorBuyBanner
          label="You are using the wrong network"
          desc="Our application uses Edifice's own blockchain. Please switch to the Edifice network in your Metamask settings."
        />
      )
    }
    return null
  }

  return (
    <div className={styles.wrapper}>
      {loading && <GlobalPreloader />}
      {successPurchase && <SuccessBuyBanner text="You have successfully completed the token redemption. The transaction will later automatically appear in the Musharakah tab" />}
      {renderFirstError()}
      <div className={styles.formInfo}>
        <p className={styles.priceInfo}>{`1 ${tokenSale.token_info.symbol} = ${formatNative(tokenSale.token_info.price)} DNR`}</p>
        <hr />
        <p className={styles.availableInfo}>{`${formatNative(tokenSale.token_info.musharakah_tokens_left)} tokens left to redeem`}</p>
      </div>
      <BuyTokenInput
        label="You pay"
        name="dnrAmount"
        value={dnrAmount}
        onChange={handleDNRAmountChange} // Используем обновленный обработчик
        staticLabelText="Your balance"
        actionLabelText={`${formatNative(balanceNative)} DNR`}
        actionLabelFunc={setMaxDnrAmount}
      />
      {formError && <p className={styles.formError}>{formError}</p>}
      <div className={styles.actionBtnWrapper} onClick={handleBuyBackTokens}>
        <p className={cx(styles.btn, {[styles.disabled]: !canBuy})}>
          {loading ? 'Loading...' : 'Redeem tokens'}
        </p>
      </div>
    </div>
  )
}

export default BuyBackMusharakahForm