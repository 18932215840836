import { ChainId } from 'web3/chains'

export const ADDRESS_REGISTRY_ADDRESS = {
  [ChainId.EDIFICE]: '',
  [ChainId.TESTNET]: '0xeaf625a6008Ff24e7CAC9c0c41b618ef9650cB25',
  [ChainId.LOCAL]: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
}

export const EXCHANGE_ADDRESS = {
  [ChainId.EDIFICE]: '',
  [ChainId.EDIFICE_TESTNET]: '0xBfBB7dc8Bc582f82e943ACEE04D895C8DD29f03C',
  [ChainId.LOCAL]: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
}