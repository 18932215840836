import { useState, useEffect, useCallback } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import AuthService from 'API/AuthService'

import useFormValidator from 'hooks/useFormValidator'
import { useUpdateUserDetails } from 'state/user/hooks'
import { setAuthToken } from 'state/user/reducer'

import CustomInputUi from 'components/UI/CustomInputUi'

import authValidator from 'validators/authValidator'

import styles from './styles.module.scss'

const SignIn = () => {
  const dispatch = useDispatch()
  const updateUserDetails = useUpdateUserDetails()

  const { inputErrors, setInputErrors, isFormValid } =
    useFormValidator(authValidator)

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate()
  const location = useLocation()
  const fromSignUp = location.state?.fromSignUp
  const fromConfirmation = location.state?.fromConfirmation
  const fromReset = location.state?.fromReset

  useEffect(() => {
    if (location.state?.error) {
      setError(location.state.error)
      navigate(location.pathname, { replace: true, state: {} })
    }
  }, [location, navigate])

  const handleInputChange = ({ target }) => {
    console.log(target)
    const { name, value } = target
    setFormData((prevData) => ({ ...prevData, [name]: value.replaceAll(' ', '') }))
    setInputErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
  }

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    setError('')

    if (!isFormValid(formData)) {
      return
    }
    setIsLoading(true)

    try {
      const data = await AuthService.signIn(formData)
      if (data.token) {
        dispatch(setAuthToken(data.token))
        navigate('/marketplace')
      }
    } catch (error) {
      console.log(error)
      setError(error?.response?.data?.message || 'Sign in process failed')
    } finally {
      setIsLoading(false)
    }
  }, [formData, dispatch, updateUserDetails])

  return (
    <div className={styles.wrapper}>
      <div className={styles.fromSignUp}>
        {fromSignUp ? (
          <p>
            Thank you for successfully registering.
            <br />
            Confirmation instructions was send to your email
          </p>
        ) : null}
        {fromConfirmation ? (
          <p>
            Your email confirmed.
            <br />
            Now enter your email and password to log in to the application.
          </p>
        ) : null}
        {fromReset ? (
          <p>
            Password reset successful. You can now login below.
          </p>
        ) : null}
      </div>
      <h4>Sign In</h4>
      <p className={styles.description}>to continue to EdificeX</p>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className={styles.formWrapper}
      >
        <CustomInputUi
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          disabled={isLoading}
          error={inputErrors.email}
          autoComplete="off"
        />
        <CustomInputUi
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleInputChange}
          disabled={isLoading}
          error={inputErrors.password}
          autoComplete="off"
        />
        {error && <div className={styles.errorMessage}>{error}</div>}
        <button
          className={styles.buttonForm}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Sign '}
        </button>
      </form>
      <NavLink className={styles.linkSwitcher} to="/forgot-password">
        Forgot password?
      </NavLink>
      <NavLink className={styles.linkSwitcher} to="/sign-up">
        {'Don\'t have an account? Go to Sign Up page'}
      </NavLink>
    </div>
  )
}

export default SignIn
