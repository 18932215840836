import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const initialState = {
  selectedWallet: undefined,
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    updateSelectedWallet(state, action) {
      state.selectedWallet = action.payload
    },
  },
})

export const {
  updateSelectedWallet,
} = walletSlice.actions

export default persistReducer({
  key: 'wallet',
  storage,
}, walletSlice.reducer)

