import styles from './styles.module.scss'
import { formatDate } from 'utils/formatDate'
import OrderDoneIcon from 'assets/images/icons/order-done.svg'
import OrderActiveIcon from 'assets/images/icons/active-order.svg'
import CancelOrderIcon from 'assets/images/icons/cancel-order.svg'

const OrderStatusUi = ({ status, date, type}) => {

  let icon, text, typeText

  switch (status) {
  case 'completed':
    text = 'Completed'
    icon = OrderDoneIcon
    break
  case 'active':
    text = 'Active'
    icon = OrderActiveIcon
    break
  case 'canceled':
    text = 'Canceled'
    icon = CancelOrderIcon
    break
  default:
    text = 'p2p order' // Текст для неизвестного статуса
    icon = OrderDoneIcon
  }

  //order.type = ["p2p", "borrower_sell_share", "investor_ownership"]
  switch (type) {
  case 'p2p':
    typeText = 'p2p order'
    break
  case 'borrower_sell_share':
    typeText = 'Musharakah sell share'
    break
  case 'investor_ownership':
    typeText = 'Musharakah investor ownership'
    break
  default:
    typeText = 'p2p order' // Текст для неизвестного статуса
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}><img src={icon} alt="" /></div>
      <div className={styles.statusInfo}>
        <p className={styles.label}>{text}</p>
        <p className={styles.date}>{formatDate(date)}</p>
        <p className={styles.type}>{typeText}</p>
      </div>
    </div>
  )
}

export default OrderStatusUi