import { useState } from 'react'
import styles from './styles.module.scss'

const TabUi = ({ children }) => {
  return <div>{children}</div>
}

const TabsUi = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const handleTabClick = (index) => {
    setActiveTab(index)
    setIsDropdownVisible(false)
  }

  const MobileDropdown = () => (
    <div className={styles.mobileDropdown}>
      <div
        className={styles.mobileDropdownHeader}
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
      >
        {children[activeTab].props.title}
      </div>
      {isDropdownVisible && (
        <div className={styles.mobileDropdownList}>
          {children
            .filter((_, index) => index !== activeTab)
            .map((child, index) => (
              <div
                className={styles.mobileDropdownItem}
                key={index}
                onClick={() => {
                  const actualIndex = child === children[activeTab] ? activeTab : index >= activeTab ? index + 1 : index
                  handleTabClick(actualIndex)
                }}
              >
                {child.props.title}
              </div>
            ))}
        </div>
      )}
    </div>
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabTitleList}>
        {children.map((child, index) => (
          <div
            className={`${styles.tabTitle} ${index === activeTab ? styles.activeTab : ''}`}
            key={index}
            onClick={() => handleTabClick(index)}
          >
            {child.props.title}
          </div>
        ))}
      </div>
      <MobileDropdown />
      <div className={styles.tabContent}>
        {children[activeTab]}
      </div>
    </div>
  )
}

export { TabsUi, TabUi }
