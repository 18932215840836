import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {}

const tranasctionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addTransaction(transactions, { payload: { chainId, from, info, hash, nonce, deadline, receipt } }) {
      if (transactions[chainId]?.[hash]) {
        throw Error('Attempted to add existing transaction')
      }
      const txs = transactions[chainId] ?? {}
      txs[hash] = { hash, from, info, addedTime: Date.now(), nonce, deadline, receipt }
      transactions[chainId] = txs
    },
    clearAllTransactions(transactions, { payload: { chainId } }) {
      if (!transactions[chainId]) return
      transactions[chainId] = {}
    },
    removeTransaction(transactions, { payload: { chainId, hash } }) {
      if (transactions[chainId][hash]) {
        delete transactions[chainId][hash]
      }
    },
    checkedTransaction(transactions, { payload: { chainId, hash, blockNumber } }) {
      const tx = transactions[chainId]?.[hash]
      if (!tx) return
      if (!tx.latestCheckedBlockNumber) {
        tx.latestCheckedBlockNumber = blockNumber
      } else {
        tx.latestCheckedBlockNumber = Math.max(blockNumber, tx.latestCheckedBlockNumber)
      }
    },
    finalizeTransaction(transactions, { payload: { chainId, hash, receipt } }) {
      const tx = transactions[chainId]?.[hash]
      if (!tx) return
      tx.receipt = receipt
      tx.confirmedTime = Date.now()
    },
  },
})

export const {
  addTransaction,
  removeTransaction,
  clearAllTransactions,
  checkedTransaction,
  finalizeTransaction,
} = tranasctionSlice.actions
export default persistReducer({
  key: 'transactions',
  storage,
}, tranasctionSlice.reducer)
