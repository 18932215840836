import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePlaidLink } from 'react-plaid-link'

import UserService from 'API/UserService'
import { useUpdateUserDetails } from 'state/user/hooks'

import TutorialCard from 'components/UI/TutorialCard'

import styles from './styles.module.scss'

const UnverifiedStatus = () => {
  const updateUserDetails = useUpdateUserDetails()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [token, setToken] = useState('')
  const [verifying, setVerifying] = useState(false)

  useEffect(() => {
    async function createToken() {
      const token = await UserService.createVerificationToken()
      setToken(token)
    }
    createToken()
  }, [])

  const onSuccess = useCallback(async (publicToken, metadata) => {
    console.log(`Finished IDV ${JSON.stringify(metadata)}. Public token ${publicToken}`)
    await UserService.completeVerification(metadata.link_session_id)
    setVerifying(false)
    updateUserDetails()
  }, [updateUserDetails])
  const onExit = useCallback(async (err, metadata) => {
    console.log(`Exited early. Error: ${JSON.stringify(err)} Metadata: ${JSON.stringify(metadata)}`)
    await UserService.completeVerification(metadata.link_session_id)
    setVerifying(false)
    updateUserDetails()
  }, [updateUserDetails])
  const onEvent = useCallback(async (eventName, metadata) => {
    console.log(`Event ${eventName}, Metadata: ${JSON.stringify(metadata)}`)
    if (eventName === 'IDENTITY_VERIFICATION_START_STEP') {
      await UserService.updateVerificationSessionToken(metadata.link_session_id)
    }
  }, [])
  const { open, ready } = usePlaidLink({ token, onSuccess, onEvent, onExit })

  // const startVerification = useCallback(async () => {
  //   setLoading(true)
  //   setError('')
  //   try {
  //     const url = await UserService.startVerification()
  //     window.open(url, '_blank')
  //     updateUserDetails()
  //   } catch (error) {
  //     setError(error.message || error)
  //   } finally {
  //     setLoading(false)
  //   }
  // }, [setError, setLoading, updateUserDetails])

  const skipVerification = useCallback(async () => {
    setLoading(true)
    setError('')
    try {
      await UserService.skipVerification()
      await updateUserDetails()
      navigate('/marketplace', { replace: true })
    } catch (error) {
      setError(error.message || error)
    } finally {
      setLoading(false)
    }
  }, [setError, setLoading, updateUserDetails, navigate])

  const startVerification = () => {
    setVerifying(true)
    open()
  }

  if (verifying) {
    return null
  }

  return (
    <TutorialCard>
      <h4>Identity Verification required</h4>
      <p>
        In order to continue to use EdificeX service, you should verify your identity
      </p>
      <div className={styles.actionBtns}>
        <button className={styles.start} disabled={!ready} onClick={startVerification}>
          {loading ? ('Loading...') : ('Start verification')}
        </button>
        <button className={styles.skip} disabled={loading} onClick={skipVerification}>
          Skip verification
        </button>
      </div>
      {error ? (
        <p>{error}</p>
      ) : null}
    </TutorialCard>
  )
}

export default UnverifiedStatus
