import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { ChainId } from 'web3/chains'

export const initialState = {
  siteNetworkId: ChainId.MUMBAI,
}

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setSiteNetworkId(state, action) {
      state.siteNetworkId = action.payload
    },
  },
})

export const {
  setSiteNetworkId,
} = networkSlice.actions

export default persistReducer({
  key: 'network',
  storage,
}, networkSlice.reducer)

