import TutorialCard from 'components/UI/TutorialCard'

const VerifyingStatus = () => (
  <TutorialCard>
    <h4>Your Identity Verification is in progress</h4>
    <p>
      Your application for verification through the plaid service is still being processed. Please wait. We will notify you if verification is successful or failed
    </p>
  </TutorialCard>
)

export default VerifyingStatus
