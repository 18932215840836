import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'

import { ChainId, CHAINS } from 'web3/chains'

export class WalletConnectPopup extends WalletConnectV2 {
  constructor({ actions, onError, showQrModal = true}) {
    super({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '',
        // chains: [ChainId.EDIFICE_TESTNET],
        optionalChains: [ChainId.EDIFICE_TESTNET],
        rpcMap: {
          [ChainId.EDIFICE_TESTNET]: CHAINS[ChainId.EDIFICE_TESTNET].urls,
        },
        showQrModal,
      },
      onError,
    })
  }
}