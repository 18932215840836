import { ethers } from 'ethers'
import { Decimal } from 'decimal.js-light'

export function formatNumber(input, digits = 2, units = 6) {
  let number
  if (typeof input === 'number') {
    number = input
  } else {
    number = parseFloat(ethers.formatUnits(String(input), units))
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(number)
}

export function formatNative(input, digits = 2) {
  const stringValue = String(input)

  const ethValue = new Decimal(stringValue).dividedBy(new Decimal('1e18'))

  const roundedEthValue = ethValue.toFixed(digits, Decimal.ROUND_DOWN)

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(roundedEthValue)
}

export function formatUSDT(input, digits = 2) {
  return formatNumber(input, digits, 6)
}

export function fromDoubleEther(num) {
  return num / 10n ** 18n
}
