import { useCallback, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

import useBlockNumber, { useFastForwardBlockNumber } from 'lib/hooks/useBlockNumber'
import { useTransactionRemover } from 'state/transactions/hooks'

import { ONE_MINUTE, SIX_HOURS } from './const'
import { CanceledError, retry, RetryableError } from './retry'

export function shouldCheck(lastBlockNumber, tx) {
  if (tx.receipt) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / ONE_MINUTE
  if (minutesPending > 60) {
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    return blocksSinceCheck > 2
  } else {
    return true
  }
}

const DEFAULT_RETRY_OPTIONS = { n: 1, minWait: 0, maxWait: 0 }

export default function Updater({ pendingTransactions, onCheck, onReceipt }) {
  const { account, chainId, provider } = useWeb3React()

  const lastBlockNumber = useBlockNumber()
  const fastForwardBlockNumber = useFastForwardBlockNumber()
  const removeTransaction = useTransactionRemover()
  // const blockTimestamp = useCurrentBlockTimestamp()

  const getReceipt = useCallback(hash => {
    if (!provider || !chainId) {
      throw new Error('No provider or chainId')
    }

    return retry(
      () =>
        provider.getTransactionReceipt(hash)
          .then(receipt => {
            if (receipt === null) {
              if (account) {
                const tx = pendingTransactions[hash]
                if (tx.addedTime + SIX_HOURS < Date.now()) {
                  removeTransaction(hash)
                }
              }

              throw new RetryableError()
            }

            return receipt
          }),
      DEFAULT_RETRY_OPTIONS,
    )
  }, [account, chainId, pendingTransactions, provider, removeTransaction])

  useEffect(() => {
    if (!chainId || !provider || !lastBlockNumber) return

    const cancels = Object.keys(pendingTransactions)
      .filter(hash => shouldCheck(lastBlockNumber, pendingTransactions[hash]))
      .map(hash => {
        const { promise, cancel } = getReceipt(hash)
        promise
          .then(receipt => {
            fastForwardBlockNumber(receipt.blockNumber)
            onReceipt({ chainId, hash, receipt })
          })
          .catch(error => {
            if (error instanceof CanceledError) return
            onCheck({ chainId, hash, blockNumber: lastBlockNumber })
          })
        return cancel
      })

    return () => {
      cancels.forEach(cancel => cancel())
    }
  }, [chainId, provider, lastBlockNumber, getReceipt, onReceipt, onCheck, pendingTransactions, fastForwardBlockNumber])

  return null
}
