import styles from './styles.module.scss'

const ImgCoverUi = ({ thumbnail, images }) => {
  if (!images && !thumbnail) {
    return <div>No images</div>
  }

  const renderContent = () => {
    const imagesCount = images ? images.length : 0

    switch (imagesCount) {
    case 1:
      return (
        <div className={styles.wrapper}>
          <div className={`${styles.imgWrapper} ${styles.mobileOnly}`}>
            <img src={thumbnail.url} alt="" />
          </div>
          <div className={styles.imgWrapper}>
            <img src={images[0].url} alt="" />
          </div>
        </div>
      )
    case 2:
      return (
        <div className={styles.wrapper}>
          <div className={`${styles.imgWrapper} ${styles.mobileOnly}`}>
            <img src={thumbnail.url} alt="" />
          </div>
          <div className={styles.imgWrapper}>
            <div className={styles.imgWrapper}>
              <img src={images[0].url} alt="" />
            </div>
            <div className={styles.imgWrapper}>
              <img src={images[1].url} alt="" />
            </div>
          </div>
        </div>
      )
    case 3:
      return (
        <div className={styles.wrapper}>
          <div className={`${styles.imgWrapper} ${styles.mobileOnly}`}>
            <img src={thumbnail.url} alt="" />
          </div>
          <div className={styles.rightSection}>
            <div className={styles.subSection}>
              <div className={styles.imgWrapper}>
                <img src={images[0].url} alt="" />
              </div>
              <div className={styles.imgWrapper}>
                <img src={images[1].url} alt="" />
              </div>
            </div>
            <div className={styles.subSection}>
              <div className={styles.imgWrapperFull}>
                <img src={images[2].url} alt="" />
              </div>
            </div>
          </div>
        </div>
      )
    case 4:
      return (
        <div className={styles.wrapper}>
          <div className={`${styles.imgWrapper} ${styles.mobileOnly}`}>
            <img src={thumbnail.url} alt="" />
          </div>
          <div className={styles.rightSection}>
            <div className={styles.subSection}>
              <div className={styles.imgWrapper}>
                <img src={images[0].url} alt="" />
              </div>
              <div className={styles.imgWrapper}>
                <img src={images[1].url} alt="" />
              </div>
            </div>
            <div className={styles.subSection}>
              <div className={styles.imgWrapper}>
                <img src={images[2].url} alt="" />
              </div>
              <div className={styles.imgWrapper}>
                <img src={images[3].url} alt="" />
              </div>
            </div>
          </div>
        </div>
      )
    default:
      return <div>Too many images</div>
    }
  }

  return renderContent()
}

export default ImgCoverUi
