const tokenSaleValidator = {
  title: {
    required: true,
    minLength: 3,
    errorMessage: 'Title must be at least 3 characters long',
  },
  description: {
    required: true,
    minLength: 3,
    errorMessage: 'Description must be at least 3 characters long',
  },
  address: {
    required: true,
    minLength: 3,
    errorMessage: 'Address must be at least 3 characters long',
  },
  city: {
    required: true,
    minLength: 3,
    errorMessage: 'City must be at least 3 characters long',
  },
  country: {
    required: true,
    minLength: 2,
    errorMessage: 'State must be at least 2 characters long',
  },
  tokenTotalSupply: {
    required: true,
    pattern: /^\d+(\.\d+)?$/,
    errorMessage: 'Token Total Supply must be a number',
  },
  tokenPrice: {
    required: true,
    pattern: /^\d+(\.\d+)?$/,
    errorMessage:
      'Token Price must be a number and can include a decimal point',
  },
  saleMinBuy: {
    required: false,
    pattern: /^\d+(\.\d+)?$/,
    errorMessage: 'Minimum amount must be a number',
  },
  saleMaxBuy: {
    required: false,
    pattern: /^\d+(\.\d+)?$/,
    errorMessage: 'Maximum amount must be a number',
  },
  saleStartAt: {
    required: false,
    // Добавляем один день к текущей дате для получения завтрашней даты
    minDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0],
    errorMessage: 'Date must not be earlier than tomorrow',
  },
  saleEndAt: {
    required: true,
  },
  rentAmount: {
    required: true,
    pattern: /^\d+(\.\d+)?$/,
    errorMessage: 'Rent amount must be a number',
  },
  projectedAnnualReturn: {
    required: true,
    pattern: /^\d+(\.\d+)?$/,
    errorMessage:
      'Projected Annual Return must be a number and can include a decimal point',
  },
  projectedRentalYield: {
    required: true,
    pattern: /^\d+(\.\d+)?$/,
    errorMessage:
      'Projected Rental Yield must be a number and can include a decimal point',
  },
  rentalYield: {
    required: true,
    pattern: /^\d+(\.\d+)?$/,
    errorMessage:
      'Rental Yield must be a number and can include a decimal point',
  },
}

export default tokenSaleValidator
