import api from './api'

const AuthService = {
  signUp: async ({ email, password, fullName, isTenant }) => {
    try {
      const response = await api.post('/register', {
        email: email.toLowerCase(),
        password,
        full_name: fullName,
        is_tenant: isTenant,
      })
      return response
    } catch (error) {
      console.log(error)
    }
  },
  signIn: async ({ email, password }) => {
    const response = await api.post('/login', {
      email: email.toLowerCase(),
      password,
    })
    return response.data.data
  },
  confirmEmail: async code => {
    const { data } = await api.post('/confirm-email', { code })
    return data
  },
  forgotPassword: async email => {
    const { data } = await api.post('/forgot-password', { email })
    return data
  },
  resetPassword: async (code, password) => {
    const { data } = await api.post('/reset-password', { code, password })
    return data
  },
}

export default AuthService
