import UserOrderItem from 'components/UserOrderItem'

import styles from './styles.module.scss'

const OrdersSection = ({ userOrders, userSellSharesOrders }) => {
  // Sort orders so that "completed" are at the end of the list
  const sortedOrders = [...userOrders].sort((a, b) => {
    if (a.status === 'completed' && b.status !== 'completed') {
      return 1
    }
    if (a.status !== 'completed' && b.status === 'completed') {
      return -1
    }
    return 0
  })

  return (
    <div className={styles.orderSection}>
      {userSellSharesOrders && userSellSharesOrders.length > 0 && (
        <>
          {userSellSharesOrders.map((item) => (
            <UserOrderItem key={Math.random()} order={item} type="investor_ownership" />
          ))}
          <hr />
        </>
      )}
      {sortedOrders.map((item) => (
        <UserOrderItem key={Math.random()} order={item} />
      ))}
    </div>
  )
}

export default OrdersSection
