import api from './api'

const UserSerivce = {
  getCurrentUser: async () => {
    const { data } = await api.get('/user')
    return data.data
  },
  setEthereumAddress: async (address, signature) => {
    await api.post('/user/set-eth-address', {address, signature})
  },
  transactions: async () => {
    const { data } = await api.get('/user/transactions')
    return data.data
  },
  orders: async () => {
    const { data } = await api.get('/user/orders')
    return data.data
  },
  sellSharesOrders: async () => {
    const { data } = await api.get('/user/sell-shares-orders')
    return data.data
  },
  musharakahTokenSales: async () => {
    const { data } = await api.get('/user/token-sales/musharakah')
    return data.data
  },
  tenementTokenSales: async () => {
    const { data } = await api.get('/user/token-sales/tenement')
    return data.data
  },
  addTenant: async (id) => {
    const { data } = await api.post(`/user/token-sales/${id}/become-tenant`)
    return data.data
  },
  createVerificationToken: async () => {
    const { data } = await api.post('/user/verification/token')
    return data.data
  },
  updateVerificationSessionToken: async token => {
    await api.post('/user/verification/session-token', { token })
  },
  completeVerification: async token => {
    await api.post('/user/verification/complete', { token })
  },
  skipVerification: async () => {
    const { data } = await api.post('/user/verification/skip')
    return data.data
  },
}

export default UserSerivce
