import axios from 'axios'

import store from 'state'
import { setAuthToken } from 'state/user/reducer'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
})

api.interceptors.request.use((config) => {
  const isLoginOrRegister = config.url.endsWith('/login') || config.url.endsWith('/register')
  const isMarketplace = config.url.endsWith('/marketplace')
  const isTokenSale = config.url.startsWith('/tokensale/')

  if (!isLoginOrRegister && !isTokenSale && !isMarketplace) {
    const token = store.getState()?.user?.authToken
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
  }
  return config
}, (error) => {
  return Promise.reject(error)
})


api.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    store.dispatch(setAuthToken(null))
    window.location.href = '/sign-in'
  }

  return Promise.reject(error.response.data.message || error.response.data.errors)
})

export default api
