// import React, { useState } from 'react'
// import { useLoaderData } from 'react-router-dom'
import styles from './styles.module.scss'
import TokenSaleCard from '../../components/TokenSaleCard'
import { TabsUi, TabUi } from '../../components/UI/TabsUi'
// import Filters from './Filters'
import { useTokenSales, useSellingMusharakah } from '../../hooks/useTokenSales'
import GlobalPreloader from 'components/UI/GlobalPreloader'

const Marketplace = () => {
  const { tokenSales, isLoading, error } = useTokenSales()
  const { sellingMusharakah, loading, isError } = useSellingMusharakah()

  if (isLoading || loading) {
    return <GlobalPreloader />
  }

  if (error || isError) {
    return <div>
      Error:
      {' '}
      {error}
    </div>
  }


  return (
    <div className={styles.wrapper}>
      <h1>Smart Investments In Tokenized Real Estate</h1>

      <p className={styles.countDesc}>
        {tokenSales.items.length}
        {' '}
        token sales
      </p>

      {/* <Filters></Filters> */}
      <TabsUi>
        <TabUi title="Declining Partnership">
          <div>
            {tokenSales.items
              .filter((tokenSale) => tokenSale.status === 'active' && (tokenSale.token_info.musharakah_allowed || tokenSale.token_info.is_musharakah)).length > 0 ? (
                <div className={styles.tokenSaleList}>
                  {tokenSales.items
                    .filter((tokenSale) => tokenSale.status === 'active' && (tokenSale.token_info.musharakah_allowed || tokenSale.token_info.is_musharakah))
                    .map((tokenSale) => (
                      <TokenSaleCard
                        key={tokenSale.id}
                        tokenSale={tokenSale}
                        status="active"
                        isMusharakah={true}
                      />
                    ))}
                </div>
              ) : (
                <p className={styles.emptyMessage}>
                  No active token sales available.
                </p>
              )}
          </div>
        </TabUi>
        <TabUi title="Ownership Resale">
          <div>
            {sellingMusharakah.length > 0 ? (
              <div className={styles.tokenSaleList}>
                {sellingMusharakah.map((item) => (
                  <TokenSaleCard
                    key={item.id}
                    tokenSale={item}
                    status="active"
                    isMusharakah={true}
                  />
                ))}
              </div>
            ) : (
              <p className={styles.emptyMessage}>
                No active token sales available.
              </p>
            )}
          </div>
        </TabUi>
        <TabUi title="Standard">
          <div>
            {tokenSales.items
              .filter((tokenSale) => tokenSale.status === 'active' && (!tokenSale.token_info.musharakah_allowed || !tokenSale.token_info.is_musharakah)).length > 0 ? (
                <div className={styles.tokenSaleList}>
                  {tokenSales.items
                    .filter((tokenSale) => tokenSale.status === 'active' && !tokenSale.token_info.musharakah_allowed)
                    .map((tokenSale) => (
                      <TokenSaleCard
                        key={tokenSale.id}
                        tokenSale={tokenSale}
                        status="active"
                        isMusharakah={tokenSale.token_info.musharakah_allowed}
                      />
                    ))}
                </div>
              ) : (
                <p className={styles.emptyMessage}>
                  No active token sales available.
                </p>
              )}
          </div>
        </TabUi>
        <TabUi title="Active">
          <div>
            {tokenSales.items
              .filter((tokenSale) => tokenSale.status === 'active').length > 0 ? (
                <div className={styles.tokenSaleList}>
                  {tokenSales.items
                    .filter((tokenSale) => tokenSale.status === 'active')
                    .map((tokenSale) => (
                      <TokenSaleCard
                        key={tokenSale.id}
                        tokenSale={tokenSale}
                        status="active"
                        isMusharakah={tokenSale.token_info.musharakah_allowed}
                      />
                    ))}
                </div>
              ) : (
                <p className={styles.emptyMessage}>
                  No active token sales available.
                </p>
              )}
          </div>
        </TabUi>
        <TabUi title="Upcoming">
          <div>
            {tokenSales.items
              .filter((tokenSale) => tokenSale.status === 'published').length ? (
                <div className={styles.tokenSaleList}>
                  {tokenSales.items
                    .filter((tokenSale) => tokenSale.status === 'published')
                    .map((tokenSale) => (
                      <TokenSaleCard
                        key={tokenSale.id}
                        tokenSale={tokenSale}
                        status="upcoming"
                      />
                    ))}
                </div>
              ) : (
                <p className={styles.emptyMessage}>
                  No upcoming token sales available.
                </p>
              )}
          </div>
        </TabUi>
        {/* <TabUi title="Ended">
          <div>
            {tokenSales.items.length > 0 ? (
              <div className={styles.tokenSaleList}>
                {tokenSales.items
                  .filter((tokenSale) => tokenSale.status === 'funded')
                  .map((tokenSale) => (
                    <TokenSaleCard
                      key={tokenSale.id}
                      tokenSale={tokenSale}
                      status="funded"
                    />
                  ))}
              </div>
            ) : (
              <p className={styles.emptyMessage}>
                No ended token sales available.
              </p>
            )}
          </div>
        </TabUi> */}
      </TabsUi>

    </div>
  )
}

export default Marketplace
