import { deepCopy } from '@ethersproject/properties'
import { JsonRpcProvider } from 'ethers'
import { isPlain } from '@reduxjs/toolkit'

import { ChainId, CHAINS } from './chains'
import { AVERAGE_L1_BLOCK_TIME } from './chainInfo'

class AppJsonRpcProvider extends JsonRpcProvider {
  #blockCache = new Map()
  #network = {}

  get blockCache() {
    if (!this.#blockCache.size) {
      this.once('block', () => this.#blockCache.clear())
    }
    return this.#blockCache
  }

  constructor(chainId) {
    const network = { chainId, name: CHAINS[chainId].name }
    super(
      CHAINS[chainId].urls[0],
      network,
      { pollingInterval: AVERAGE_L1_BLOCK_TIME },
    )

    this.#network = network
  }

  /**
   * Override default implementation, because parent class converts chainId to BigInt
   * which is fails check in web3-react package here https://github.com/Uniswap/web3-react/blob/main/packages/store/src/index.ts#L13
   */
  async getNetwork() {
    return this.#network
  }

  send(method, params) {
    if (method !== 'eth_call') {
      return super.send(method, params)
    }

    if (!isPlain(params)) {
      return super.send(method, params)
    }

    const key = `call:${JSON.stringify(params)}`
    const cached = this.blockCache.get(key)
    if (cached) {
      this.emit('debug', {
        action: 'request',
        request: deepCopy({ method, params, id: 'cache' }),
        provider: this,
      })
      return cached
    }

    const result = super.send(method, params)
    this.blockCache.set(key, result)
    return result
  }
}

export const RPC_PROVIDERS = {
  // [ChainId.EDIFICE]: new AppJsonRpcProvider(ChainId.EDIFICE),
  [ChainId.EDIFICE_TESTNET]: new AppJsonRpcProvider(ChainId.EDIFICE_TESTNET),
  [ChainId.LOCAL]: new AppJsonRpcProvider(ChainId.LOCAL),
}