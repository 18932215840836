import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'

function getVerificationMessage(userId) {
  return `EdificeX user ${userId} has full control of this address`
}

export function useSignVerificationMessage() {
  const { account, provider } = useWeb3React()

  return useCallback(userId => {
    if (!account || !userId) {
      return
    }

    const message = getVerificationMessage(userId)
    const signer = provider.getSigner(account)
    return signer.signMessage(message)
  }, [account, provider])
}
