import { Navigate } from 'react-router-dom'

import { useUserDetails } from 'state/user/hooks'

import UnverifiedStatus from './UnverifiedStatus'
import VerifyingStatus from './VerifyingStatus'
import FailedStatus from './FailedStatus'

const IdentityVerification = () => {
  const userDetails = useUserDetails()

  if (userDetails?.status === 'verified') {
    return <Navigate to="/marketplace" replace />
  }

  if (userDetails?.status === 'unverified') {
    return <UnverifiedStatus />
  }

  if (userDetails?.status === 'verifying') {
    return <VerifyingStatus />
  }

  if (userDetails?.status === 'verification_failed') {
    return <FailedStatus />
  }

  return null
}

export default IdentityVerification
