import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify'

import { addTransaction, removeTransaction } from './reducer'

export function useTransactionAdder() {
  const { chainId, account } = useWeb3React()
  const dispatch = useDispatch()

  return useCallback((response, info, deadline) => {
    if (!account || !chainId) {
      return
    }

    const { hash, nonce } = response
    if (!hash) {
      throw Error('No transaction hash found')
    }
    dispatch(addTransaction({ hash, from: account, info, chainId, nonce, deadline }))
    toast.loading('Waiting for transaction to complete', { toastId: hash, updateId: hash })
  }, [account, chainId, dispatch])
}

export function useTransactionRemover() {
  const { chainId, account } = useWeb3React()
  const dispatch = useDispatch()

  return useCallback(hash => {
    if (!account || !chainId) {
      return
    }

    dispatch(removeTransaction({ hash, chainId }))
  }, [account, chainId, dispatch])
}

export function useAllTransactions() {
  const { chainId } = useWeb3React()
  const state = useSelector(state => state.transactions)

  return chainId ? state[chainId] ?? {} : {}
}

export function usePendingTransactions() {
  const transactions = useAllTransactions()

  return useMemo(() => {
    return Object.values(transactions).reduce((acc, tx) => {
      if (!tx.receipt) acc[tx.hash] = tx
      return acc
    }, {})
  }, [transactions])
}

export function useTransaction(hash) {
  const allTransactions = useAllTransactions()
  if (!hash) {
    return undefined
  }

  return allTransactions[hash]
}

export function useIsTransactionPending(hash) {
  const transaction = useTransaction(hash)
  if (!transaction) {
    return false
  }

  return !transaction.receipt
}

export function useIsTransactionConfirmed(hash) {
  const transaction = useTransaction(hash)
  if (!transaction) {
    return false
  }

  return !!transaction.receipt
}

export function useHasPendingTransactions() {
  const transactions = useAllTransactions()
  return useMemo(() => Object.values(transactions).filter(tx => !tx.receipt).length > 0, [transactions])
}
