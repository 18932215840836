import cx from 'classnames'

import styles from './styles.module.scss'

const Option = ({ connection, activate, pendingConnectionType }) => {
  const isPending = connection.type === pendingConnectionType

  return (
    <button
      type="button"
      className={cx(styles['wallet-button'], {[styles.disabled]: !isPending && !!pendingConnectionType})}
      onClick={!pendingConnectionType ? activate : undefined}
    >
      <img src={connection.getIcon?.()} />
      <h3>
        {connection.getName()}
      </h3>
      {isPending && 'Connecting'}
    </button>
  )
}

export default Option