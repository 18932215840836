import { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import moment from 'moment'

import UserSerivce from 'API/UserService'

import {
  setUserDetails,
  setUserBalanceNative,
  setUserTransactions,
  setUserOrders,
  setUserSellSharesOrders,
  setUserMusharakahTokenSales,
  setUserTenementTokenSales,
} from './reducer'

export function useUpdateUserDetails() {
  const dispatch = useDispatch()
  const isUserAuthorized = useIsUserAuthorized()

  return useCallback(async () => {
    if (!isUserAuthorized) {
      dispatch(setUserDetails(null))
      dispatch(setUserBalanceNative('0'))
      return
    }
    try {
      const details = await UserSerivce.getCurrentUser()
      dispatch(setUserDetails(details))
    } catch (error) {
      console.error(`Failed to fetch user details: ${error}`)
    }
  }, [isUserAuthorized, dispatch])
}

export function useUpdateUserBalanceNative() {
  const { account, provider } = useWeb3React()
  const dispatch = useDispatch()

  return useCallback(async () => {
    if (!provider) {
      return
    }
    if (!account) {
      return
    }

    try {
      const balance = await provider.getBalance(account)
      dispatch(setUserBalanceNative(balance.toString()))
    } catch (error) {
      console.error('Failed to get user native balance', error)
    }
  }, [account, provider, dispatch])
}

export function useUpdateUserTransactions() {
  const dispatch = useDispatch()

  return useCallback(async () => {
    const txs = await UserSerivce.transactions()
    dispatch(setUserTransactions(txs))
  }, [ dispatch ])
}

export function useUpdateUserOrders() {
  const dispatch = useDispatch()

  return useCallback(async () => {
    const orders = await UserSerivce.orders()
    dispatch(setUserOrders(orders))
  }, [ dispatch ])
}

export function useUpdateUserSellSharesOrders() {
  const dispatch = useDispatch()
  const isUserAuthorized = useIsUserAuthorized()

  return useCallback(async () => {
    if (!isUserAuthorized) {
      dispatch(setUserSellSharesOrders([]))
      return
    }
    const orders = await UserSerivce.sellSharesOrders()
    dispatch(setUserSellSharesOrders(orders))
  }, [ dispatch ])
}

export function useUpdateUserMusharakahTokenSales() {
  const dispatch = useDispatch()

  return useCallback(async () => {
    const tokenSales = await UserSerivce.musharakahTokenSales()
    dispatch(setUserMusharakahTokenSales(tokenSales))
  }, [ dispatch ])
}

export function useUpdateUserTenementTokenSales() {
  const dispatch = useDispatch()
  const isUserAuthorized = useIsUserAuthorized()

  return useCallback(async () => {
    if (!isUserAuthorized) {
      dispatch(setUserTenementTokenSales([]))
      return
    }
    const tokenSales = await UserSerivce.tenementTokenSales()
    dispatch(setUserTenementTokenSales(tokenSales))
  }, [ isUserAuthorized, dispatch ])
}

export function useUserDetails() {
  return useSelector(state => state.user.details)
}

export function useUserBalanceNative() {
  return useSelector(state => BigInt(state.user.balanceNative))
}

export function useIsUserAuthorized() {
  return !!useSelector(state => state.user.authToken)
}

export function useIsCorrectAddress() {
  const { account } = useWeb3React()
  const userDetails = useUserDetails()
  return account?.toLowerCase() === userDetails?.eth_address.toLowerCase()
}

export function useIsWalletConnected() {
  const { account } = useWeb3React()
  return !!account
}

export function useUserTransactions() {
  return useSelector(state => state.user.transactions)
}

export function useUserTransactionsGrouppedByDate() {
  const tokenTxs = useUserTransactions()

  return useMemo(() => {
    const plain = tokenTxs.reduce((acc, t) => {
      return [
        ...acc,
        ...t.transactions.map(tx => ({
          tokenName: t.name,
          tokenSymbol: t.symbol,
          contractAddress: t.contract_address,
          tokenPrice: t.price,
          ...tx,
        })),
      ]
    }, [])
    return plain.reduce((acc, tx) => {
      const date = moment(tx.created_at).format('YYYY-MM-DD')
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(tx)

      return acc
    }, {})
  }, [JSON.stringify(tokenTxs)])
}

export function useUserOrders() {
  return useSelector(state => state.user.orders)
}

export function useUserSellSharesOrders() {
  return useSelector(state => state.user.sellSharesOrders)
}

export function useUserMusharakahTokenSales() {
  return useSelector(state => state.user.musharakahTokenSales)
}

export function useUserTenementTokenSales() {
  return useSelector(state => state.user.tenementTokenSales)
}
