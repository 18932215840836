import PortfolioAssetUi from 'components/UI/PortfolioAssetUi'

import styles from './styles.module.scss'

const OverviewSection = ({ tokenTransactions }) => (
  <div className={styles.overviewSection}>
    {tokenTransactions.map((asset) => (
      <PortfolioAssetUi key={asset.name} asset={asset} />
    ))}
  </div>
)

export default OverviewSection
