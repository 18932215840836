import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import LibUpdater from 'lib/transactions'

import { TransactionSuccessed, TransactionError } from 'components/UI/TransactionToast'

import { usePendingTransactions } from './hooks'
import { checkedTransaction, finalizeTransaction } from './reducer'

export function toSerializableReceipt(receipt) {
  return {
    blockHash: receipt.blockHash,
    blockNumber: receipt.blockNumber,
    contractAddress: receipt.contractAddress,
    from: receipt.from,
    status: receipt.status,
    to: receipt.to,
    transactionHash: receipt.transactionHash,
    transactionIndex: receipt.transactionIndex,
  }
}

export default function Updater() {
  const dispatch = useDispatch()
  const pendingTransactions = usePendingTransactions()

  const onCheck = useCallback(({ chainId, hash, blockNumber }) => {
    dispatch(checkedTransaction({ chainId, hash, blockNumber }))
  }, [dispatch])
  const onReceipt = useCallback(({ chainId, hash, receipt }) => {
    dispatch(finalizeTransaction({
      chainId,
      hash,
      receipt: toSerializableReceipt(receipt),
    }))
    const tx = pendingTransactions[hash]
    const { message } = tx.info

    if (toast.isActive(hash)) {
      if (receipt.status === 0) {
        toast.update(hash, { render: <TransactionError message={message} />, type: 'error', isLoading: false, autoClose: 5000 })
      } else {
        toast.update(hash, { render: <TransactionSuccessed message={message} />, type: 'success', isLoading: false, autoClose: 5000 })
      }
    } else {
      if (receipt.status === 0) {
        toast.error(<TransactionError message={message} />)
      } else {
        toast.success(<TransactionSuccessed message={message} />)
      }
    }
  }, [dispatch, pendingTransactions])

  return (
    <LibUpdater
      pendingTransactions={pendingTransactions}
      onCheck={onCheck}
      onReceipt={onReceipt}
    />
  )
}
