import styles from './styles.module.scss'
import { NavLink } from 'react-router-dom'

const SuccessRequestCreation = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.message}>
        Thank you very much for your message. We will review your application
        for a token sale and respond to your email.
      </p>
      <NavLink to="/marketplace">Back to Marketplace page</NavLink>
    </div>
  )
}

export default SuccessRequestCreation
