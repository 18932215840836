import { useMemo } from 'react'
import { Web3ReactProvider } from '@web3-react/core'

import useEagerlyConnect from 'hooks/useEagerlyConnect'
import useOrderedConnections from 'hooks/useOrderedConnections'

export default function Web3Provider({ children }) {
  useEagerlyConnect()
  const connections = useOrderedConnections()
  const connectors = connections.map(({ connector, hooks }) => [connector, hooks])

  const key = useMemo(() => connections.map(connection => connection.getName()).join('-'), [connections])

  return (
    <Web3ReactProvider connectors={connectors} key={key}>
      {children}
    </Web3ReactProvider>
  )
}