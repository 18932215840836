import { useState, useCallback } from 'react'
import { NavLink } from 'react-router-dom'

import AuthService from 'API/AuthService'

import useFormValidator from 'hooks/useFormValidator'

import CustomInputUi from 'components/UI/CustomInputUi'

import forgotPasswordValidator from 'validators/forgotPasswordValidator'

import styles from './styles.module.scss'

const ForgotPassword = () => {
  const { inputErrors, setInputErrors, isFormValid } =
    useFormValidator(forgotPasswordValidator)

  const [formData, setFormData] = useState({
    email: '',
  })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const handleInputChange = ({ target }) => {
    const { name, value } = target
    setFormData((prevData) => ({ ...prevData, [name]: value?.trim() }))
    setInputErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
  }

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    setError('')

    if (!isFormValid(formData)) {
      return
    }
    setIsLoading(true)

    try {
      await AuthService.forgotPassword(formData.email)
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setError(error?.response?.data?.message || 'Failed')
    } finally {
      setIsLoading(false)
    }
  }, [formData])

  return (
    <div className={styles.wrapper}>
      <h4>Forgot password</h4>
      {success ? (
        <div className={styles.description}>
          An email with reset instructions has been sent to your email
        </div>
      ) : (
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          className={styles.formWrapper}
        >
          <CustomInputUi
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            disabled={isLoading}
            error={inputErrors.email}
            autoComplete="off"
          />
          {error && <div className={styles.errorMessage}>{error}</div>}
          <button
            className={styles.buttonForm}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Send'}
          </button>
        </form>
      )}
      <NavLink className={styles.linkSwitcher} to="/sign-in">
        Return to Sign In page
      </NavLink>
    </div>
  )
}

export default ForgotPassword
