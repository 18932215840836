import { createBrowserRouter } from 'react-router-dom'

import OrderService from 'API/OrderService'
import TokenSaleService from 'API/TokenSaleService'

import Layout from 'components/Layout'

import ProtectedRoute from './ProtectedRoute'

import SignUp from 'pages/Auth/SignUp'
import SignIn from 'pages/Auth/SignIn'
import ConfirmEmail from 'pages/Auth/ConfirmEmail'
import ForgotPassword from 'pages/Auth/ForgotPassword'
import ResetPassword from 'pages/Auth/ResetPassword'
import Marketplace from 'pages/Marketplace'
import TokenSale from 'pages/TokenSale'
import Portfolio from 'pages/Portfolio'
import Exchange from 'pages/Exchange'
import P2pForm from 'pages/P2pForm'
import RequestTokenSale from 'pages/RequestTokenSale'
import SuccessRequestCreation from 'pages/SuccessRequestCreation'
import CreateOrder from 'pages/CreateOrder'
import VerifyEthereumAddress from 'pages/VerifyEthereumAddress'
import SelectRentPropery from 'pages/SelectRentProperty'
import RentPayment from 'pages/RentPayment'
import CreateInvestorOwnershipOrder from 'pages/CreateInvestorOwnershipOrder'
import P2pShareForm from 'pages/P2pShareForm'
import IdentityVerification from 'pages/IdentityVerification'
import About from 'pages/About'
import Landing from 'pages/Landing'

export default createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'confirm-email',
        element: <ConfirmEmail />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'wallet',
        element: (
          <ProtectedRoute>
            <Portfolio />
          </ProtectedRoute>
        ),
      },
      {
        path: 'p2p-exchange',
        element: <Exchange />,
      },
      {
        path: 'request-tokensale',
        element: (
          <ProtectedRoute>
            <RequestTokenSale />
          </ProtectedRoute>
        ),
      },
      {
        path: 'create-order',
        element: (
          <ProtectedRoute>
            <CreateOrder />
          </ProtectedRoute>
        ),
      },
      {
        path: 'request-tokensale-success',
        element: (
          <ProtectedRoute>
            <SuccessRequestCreation />
          </ProtectedRoute>
        ),
      },
      {
        path: 'select-rent-property',
        element: (
          <ProtectedRoute>
            <SelectRentPropery />
          </ProtectedRoute>
        ),
      },
      {
        path: 'create-ownership-tokens-order',
        element: (
          <ProtectedRoute>
            <CreateInvestorOwnershipOrder />
          </ProtectedRoute>
        ),
      },
      {
        path: 'rent-payment/:id',
        loader: async ({ params }) => {
          if (!params.id) {
            throw new Response('Not Found', { status: 404 })
          }
          try {
            const [tokenSale, rentDepositTransactions] = await Promise.all([
              TokenSaleService.getTokenSale(params.id),
              TokenSaleService.fetchDepositRentTransactions(params.id),
            ])
            return {tokenSale, rentDepositTransactions}
          } catch (error) {
            throw new Response('Not Found', { status: 404 })
          }
        },
        element: (
          <ProtectedRoute>
            <RentPayment />
          </ProtectedRoute>
        ),
      },
      {
        path: 'marketplace',
        element: <Marketplace />,
      },
      {
        path: 'exchange',
        loader: async ({ request }) => {
          const url = new URL(request.url)
          const orderId = url.searchParams.get('id')
          if (!orderId) {
            throw new Response('Not Found', { status: 404 })
          }
          try {
            return await OrderService.order(orderId)
          } catch (error) {
            throw new Response('Not Found', { status: 404 })
          }
        },
        element: <P2pForm />,
      },
      {
        path: 'tokensale/:id',
        element: <TokenSale />,
      },
      {
        path: 'exchange-share',
        element: <P2pShareForm />,
      },
      {
        path: 'verify-eth-address',
        element: (
          <ProtectedRoute>
            <VerifyEthereumAddress />
          </ProtectedRoute>
        ),
      },
      {
        path: 'identity-verification',
        element: (
          <ProtectedRoute>
            <IdentityVerification />
          </ProtectedRoute>
        ),
      },
    ],
  },
])
