import { useState } from 'react'
// import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'

import { DEFAULT_CHAIN_ID } from 'web3/chains'

// import { updateSelectedWallet } from 'state/wallet/reducer'
import { useUserBalanceNative } from 'state/user/hooks'
import { useActiveNetwork, useSwitchNetwork } from 'state/network/hooks'

import ModalUi from 'components/UI/ModalUi'
import WalletModal from 'components/WalletModal'

import buyBtnIcon from 'assets/images/icons/header-icons/Add.svg'

import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'

const ConnectButton = () => {
  // const dispatch = useDispatch()
  const {
    account,
    // connector,
  } = useWeb3React()

  const nativeBalance = useUserBalanceNative()
  const activeNetwork = useActiveNetwork()
  const switchNetwork = useSwitchNetwork()

  const [modalOpen, setModalOpen] = useState(false)

  const connected = !!account

  // const disconnect = useCallback(() => {
  //   if (connector && connector.deactivate) {
  //     connector.deactivate()
  //   }

  //   connector.resetState()
  //   dispatch(updateSelectedWallet(undefined))
  // }, [connector, dispatch])

  const handleModalClose = () => setModalOpen(false)

  if (!connected) {
    return (
      <>
        <div className={styles.connectWallet} onClick={() => setModalOpen(true)}>
          Connect Wallet
        </div>
        <ModalUi width={500} isOpen={modalOpen} close={handleModalClose} header="Connect Wallet">
          <WalletModal onClose={handleModalClose} />
        </ModalUi>
      </>
    )
  }

  if (!activeNetwork) {
    return (
      <div className={styles.connectWallet} onClick={() => switchNetwork(DEFAULT_CHAIN_ID)}>
        Switch network
      </div>
    )
  }

  return (
    <div className={styles.balanceWrapper}>
      <div className={styles.balanceInfo}>
        <div className={styles.title}>Balance</div>

        <div className={styles.amount}>
          {`${formatNative(nativeBalance)} DNR`}
        </div>
      </div>

      <div className={styles.buyBtn}>
        <img src={buyBtnIcon} alt="buy nativa currency" />
      </div>
    </div>
  )
}

export default ConnectButton
