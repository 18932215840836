import { useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'

import AuthService from 'API/AuthService'
import useFormValidator from 'hooks/useFormValidator'

import CustomInputUi from 'components/UI/CustomInputUi'

import registrationValidator from 'validators/registrationValidator'

import styles from './styles.module.scss'

const SignUp = () => {
  const { inputErrors, setInputErrors, isFormValid } = useFormValidator(
    registrationValidator,
  )

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    isTenant: false,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.state?.error) {
      setError(location.state.error)
      navigate(location.pathname, { replace: true, state: {} })
    }
  }, [location, navigate])

  const handleInputChange = ({ target }) => {
    const { name, value } = target

    const newValue = name === 'email' ? value.replaceAll(' ', '') : value

    setFormData((prevData) => ({ ...prevData, [name]: newValue }))
    setInputErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
  }

  const handleIsTenantSwitch = isTenant => {
    setFormData(state => ({...state, isTenant}))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setError('')

    if (!isFormValid(formData)) {
      return
    }

    // if (!userRole && userRole === '') {
    //   setError('You should choose one role')
    //   return
    // }

    setIsLoading(true)

    try {
      const data = await AuthService.signUp(formData)
      if (data.data.status === 'success') {
        setIsSignUpSuccess(true)
        // navigate('/sign-in', { state: { fromSignUp: true } })
      }
    } catch (error) {
      console.log(error)
      setError(error?.response?.data?.errors?.email || 'This email has already been registered')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.wrapper}>
      {
        isSignUpSuccess ? (<div>
          <h4>Thank You for Registering!</h4>
          <p className={styles.description}>
            To complete your registration, please check your email inbox for a confirmation message from us. Click on the unique link provided in the email to verify your email address.

            If you can not find the email, please check your spam or junk folder. Sometimes emails end up there by mistake.
          </p>
        </div>) : (<>
          {' '}


          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className={styles.formWrapper}
          >
            <CustomInputUi
              label="Full Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              disabled={isLoading}
              error={inputErrors.fullName}
              autoComplete="off"
            />
            <CustomInputUi
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              disabled={isLoading}
              error={inputErrors.email}
              autoComplete="off"
            />
            <CustomInputUi
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              disabled={isLoading}
              error={inputErrors.password}
              autoComplete="off"
            />
            <div onClick={() => handleIsTenantSwitch(false)} className={`${styles.selector} ${!formData.isTenant ? styles.active : ''}`}>
              <p className={styles.selectorTitle}>I&apos;m an investor</p>
              <p className={styles.selectorDesc}>I&apos;m going to participate in token sales</p>
            </div>
            <div onClick={() => handleIsTenantSwitch(true)} className={`${styles.selector} ${formData.isTenant ? styles.active : ''}`}>
              <p className={styles.selectorTitle}>I&apos;m a tenant</p>
              <p className={styles.selectorDesc}>I&apos;m going to pay rent</p>
            </div>
            {error && <div className={styles.errorMessage}>{error}</div>}
            <button
              className={styles.buttonForm}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Sign up'}
            </button>
          </form>
          <NavLink className={styles.linkSwitcher} to="/sign-in">
            If you have an account, go to the Sign In page
          </NavLink>
        </>)
      }

    </div>
  )
}

export default SignUp
