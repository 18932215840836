import { useCallback, useState } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useWeb3React } from '@web3-react/core'

import UserService from 'API/UserService'

import { useSignVerificationMessage } from 'hooks/useSignVerificationMessage'
import { useActiveNetwork } from 'state/network/hooks'

import { useUserDetails } from 'state/user/hooks'
import { setUserETHAddress } from 'state/user/reducer'

import styles from './styles.module.scss'
import ConnectButton from 'components/ConnectButton'
import TutorialCard from 'components/UI/TutorialCard'

import WalletIcon from 'assets/images/icons/wallet-icon.svg'
import SwitchIcon from 'assets/images/icons/switch-icon.svg'
import SignDocIcon from 'assets/images/icons/sign-doc-icon.svg'

const VerifyEthereumAddress = () => {
  const { account} = useWeb3React()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const signMessage = useSignVerificationMessage()
  const userDetails = useUserDetails()

  const activeNetwork = useActiveNetwork()

  const [error, setError] = useState(null)

  const handleUpdateAddress = useCallback(async () => {
    try {
      const signature = await signMessage(userDetails.id)
      if (!signature) {
        return
      }
      await UserService.setEthereumAddress(account, signature)
      dispatch(setUserETHAddress(account))
      navigate('/marketplace', { replace: true })
    } catch (error) {
      setError(`Failed to set ETH address ${error}. Choose a different address for authorization, because this address is already used on another account`)
      console.error(`Failed to set ETH address: ${error}`)
    }
  }, [account, userDetails, signMessage, dispatch, navigate])

  if (!account) {
    return (
      <TutorialCard>
        <div className={styles.iconWrapper}>
          <img src={WalletIcon} alt="" />
        </div>
        <h4>Connect your wallet</h4>
        <p>In order to use our application you need to connect your Metamask wallet. The wallet address is assigned to you forever and cannot be changed. If you have such a need, write to the support service. If you do not have the Metamask application installed, then below there will be a link to the official website for downloading and installing Metamask.</p>
        <ConnectButton />
        <a href="https://metamask.io/download/" target='_blank' rel="noreferrer">MetaMask download link</a>
      </TutorialCard>
    )
  }

  if (!activeNetwork) {
    return (
      <TutorialCard>
        <div className={styles.iconWrapper}>
          <img src={SwitchIcon} alt="" />
        </div>
        <h4>Switch blockchain network</h4>
        <p>In order to use our application you need to use our own Edifice blockchain. In order to switch to our blockchain network, click the “Switch network” button and follow the instructions in your Metamask.</p>
        <ConnectButton />
      </TutorialCard>
    )
    // return (
    //   <div className={styles.switchNetwork} onClick={() => switchNetwork(ChainId.TESTNET)}>
    //     Switch network
    //   </div>
    // )
  }

  if (!userDetails) {
    return <p>You need to connect Wallet</p>
  }

  if (userDetails.eth_address) {
    return (
      <Navigate to="/marketplace" replace />
    )
  }

  return (
    <TutorialCard>
      <div className={styles.iconWrapper}>
        <img src={SignDocIcon} alt="" />
      </div>
      <h4>Adding a wallet to your account</h4>
      <p>For application security purposes, each user is assigned his wallet address from which you will conduct all financial transactions. This is done to enforce anti-money laundering laws.</p>
      {error && <h6>{error}</h6>}
      <button className={styles.verifyBtn} onClick={handleUpdateAddress}>
        Sign and send
      </button>
    </TutorialCard>
  )

}

export default VerifyEthereumAddress
