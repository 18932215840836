import api from './api'

const OrderService = {
  ordersList: async () => {
    const { data } = await api.get('/orders')
    return data.data
  },
  order: async (orderId) => {
    const { data } = await api.get(`/orders/${orderId}`)
    return data.data
  },

  fetchSellShareOrders: async () => {
    const { data } = await api.get('/sell-share-orders')
    return data.data
  },
}

export default OrderService
