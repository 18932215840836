import styles from './styles.module.scss'

const ErrorBuyBanner = ({label, desc}) => {
  return (
    <div className={styles.errorBanner}>
      <p className={styles.labelError}>{label}</p>
      <p className={styles.descError}>{desc}</p>
    </div>
  )
}

export default ErrorBuyBanner