import { useState } from 'react'
import styles from './styles.module.scss'

const DetailsTabsUi = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible)

  // Обработчик клика по элементу списка, который также скрывает дропдаун
  const handleDropdownItemClick = (label) => {
    setActiveTab(label)
    toggleDropdown()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={`${styles.tab} ${activeTab === tab.label ? styles.active : ''}`}
            onClick={() => setActiveTab(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className={styles.mobileDropdown} onClick={toggleDropdown}>
        <div className={styles.mobileDropdownHeader}>
          {activeTab}
        </div>
        {isDropdownVisible && (
          <div className={styles.mobileDropdownList}>
            {tabs.filter(tab => tab.label !== activeTab).map((tab) => (
              <div
                className={styles.mobileDropdownItem}
                key={tab.label}
                onClick={() => handleDropdownItemClick(tab.label)}
              >
                {tab.label}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.content}>
        {tabs.find((tab) => tab.label === activeTab)?.content}
      </div>
    </div>
  )
}

export default DetailsTabsUi
