import {
  useUserTenementTokenSales,
} from 'state/user/hooks'


import RentPaymentsItem from 'components/RentPaymentsItem'

const RentPayments = () => {
  const tokenSales = useUserTenementTokenSales()

  return (
    tokenSales.map((tokenSale) => (<RentPaymentsItem key={tokenSale.id} tokenSale={tokenSale} />))
  )
}

export default RentPayments