import moment from 'moment'

import arrowDownImg from 'assets/images/icons/arrow-down-green.svg'
import tokenLogo from 'assets/images/icons/edifice-logo.svg'
import completedIcon from 'assets/images/icons/completed.svg'

import { truncateTxHash } from 'utils/formatAddress'
import { formatNative } from 'utils/formatNumber'

import styles from './styles.module.scss'

const DepositTransaction = ({ transaction, isMusharakah }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftSection}>
        <div className={styles.info}>
          <div className={styles.iconWrapper}>
            <img src={arrowDownImg} alt="" />
          </div>
          <div className={styles.txtData}>
            <p className={styles.type}>
              {isMusharakah ? 'Musharakah Payment' : 'Rent Payment'}
            </p>
            <p className={styles.date}>
              {moment(transaction.created_at).format('DD/MM/YYYY hh:mm A')}
            </p>
          </div>
        </div>

        <div className={styles.primaryAsset}>
          <div className={styles.iconWrapper}>
            <img src={tokenLogo} alt="" />
          </div>
          <div className={styles.txtData}>
            <p className={styles.amount}>
              {`${formatNative(transaction.data.amount)} DNR`}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div
          style={{
            backgroundColor:
              '#61E99F',
          }}
          className={styles.statusWrapper}
        >
          <img
            className={styles.statusImg}
            src={completedIcon}
            alt=""
          />
          <p className={styles.statusTxt}>
            Completed
          </p>
        </div>

        <a
          className={styles.transactionLink}
          target="_blank"
          href={`https://explorer-testnet.edifice.network/tx/${transaction.hash}`}
          rel="noreferrer"
        >
          {truncateTxHash(transaction.hash)}
        </a>
      </div>
      <div className={styles.mobileSection}>
        <a
          className={styles.mobLink}
          target="_blank"
          href={`https://explorer-testnet.edifice.network/tx/${transaction.hash}`}
          rel="noreferrer"
        >
          View on exlorer
        </a>
      </div>
    </div>
  )
}

export default DepositTransaction