import styles from './styles.module.scss'
import SuccessIcon from '../../../assets/images/icons/success.svg'

const SuccessBuyBanner = ({text, closeLink=false}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}><img src={SuccessIcon} alt="" /></div>
      <p className={styles.title}>Success!</p>
      <p className={styles.description}>{text}</p>
      {/* <p className={styles.description}>The purchase of tokens was successful. They have been sent your wallet. You can check your balance both in Metamask and on the Wallet page</p> */}
      {!closeLink && <a className={styles.link} href="/wallet">Go to Wallet page</a>}
    </div>
  )
}

export default SuccessBuyBanner